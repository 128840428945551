import { Title } from '@mantine/core';
import DarkModeSwitch from '@/components/Settings/DarkModeSwitch';
import ThemeColor from '@/components/Settings/ThemeColor';

export default function ThemeSettings() {
  return (
    <>
      <Title order={6} style={{ marginTop: 0 }}>Dark mode</Title>
      <DarkModeSwitch />
      <Title order={6}>Theme color</Title>
      <ThemeColor />
    </>
  );
}
