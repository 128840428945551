import {
  Combobox, Group, InputBase, InputBaseProps, ScrollAreaAutosize, useCombobox,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { CoinDisplayField, coinDisplayFields } from '@/constants/coinFields';

const displayFields = Object.entries(coinDisplayFields).map(([key, obj]) => ({
  ...obj,
  value: key as CoinDisplayField,
}));

const labelFromVal = (val: string) => displayFields.find(({ value }) => value === val)?.label ?? '';

export default function CoinDisplayFieldSelect({
  value, onChange: selectValue, setOpen, rightSection, ...other
}: {
  value: CoinDisplayField,
  onChange: (field: CoinDisplayField) => void,
  setOpen?: (open: boolean) => void,
} & Omit<InputBaseProps, 'value' | 'onChange' | 'leftSection' | 'onClick' | 'onFocus' | 'onBlur' | 'placeholder'>) {
  const { icon: SelectedIcon, label: selectedLabel } = coinDisplayFields[value];

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onOpenedChange: (isOpen) => setOpen?.(isOpen),
  });

  const [search, setSearch] = useState(selectedLabel);

  const shouldFilterOptions = displayFields.every(
    ({ label }) => label.toLowerCase() !== search.toLowerCase(),
  ) || search.toLowerCase() !== selectedLabel.toLowerCase();
  const searchIndex = (val: string) => val.toLowerCase().indexOf(search.toLowerCase());
  const filteredOptions = !shouldFilterOptions ? displayFields : displayFields
    .filter(({ label }) => label.toLowerCase().includes(search.toLowerCase().trim()))
    .sort(({ label: a }, { label: b }) => searchIndex(a) - searchIndex(b) || a.localeCompare(b));

  const options = filteredOptions.map(({ value: optionVal, label, icon: Icon }) => (
    <Combobox.Option value={optionVal} key={optionVal} data-label={label}>
      <Group wrap="nowrap" gap="xs">
        <Icon size={16} strokeWidth={1.5} />
        {label}
      </Group>
    </Combobox.Option>
  ));

  useEffect(() => {
    if (shouldFilterOptions) {
      combobox.selectFirstOption();
    } else {
      combobox.resetSelectedOption();
    }
  }, [combobox, shouldFilterOptions]);

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        selectValue(val as CoinDisplayField);
        setSearch(labelFromVal(val));
        combobox.closeDropdown();
        setOpen?.(false);
      }}
    >
      <Combobox.Target>
        <InputBase
          leftSection={<SelectedIcon size={16} strokeWidth={1.5} />}
          rightSection={rightSection ?? <Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            setOpen?.(true);
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => { combobox.openDropdown(); }}
          onFocus={() => { combobox.openDropdown(); }}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(labelFromVal(value) || '');
          }}
          placeholder="Search value"
          rightSectionPointerEvents={rightSection ? undefined : 'none'}
          {...other}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollAreaAutosize mah={200} type="auto">
            {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
          </ScrollAreaAutosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
