import {
  Avatar, Button, FileButton, Group, LoadingOverlay, Stack, TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconAt, IconEraser, IconLink, IconRefresh, IconUpload, IconUser,
} from '@tabler/icons-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditUser, useUser } from '@/db/auth';
import { EditAuthUser, EditAuthUserSchema } from '@/db/user/schema';
import { errorMsg, queryHandler } from '@/helpers/$fetch';
import getUserLetters from '@/helpers/getUserLetters';
import validateUsername from '@/helpers/validateUsername';
import useImageForm from '@/hooks/useImageForm';
import useThemeColor from '@/hooks/useThemeColor';
import classes from './EditProfile.module.css';

export default function EditProfile() {
  const [primaryColor] = useThemeColor();

  const { data: user } = useUser();
  const { mutateAsync: editUser, isPending } = useEditUser();

  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const form = useForm<EditAuthUser>({
    validate: import.meta.env.DEV ? zodResolver(EditAuthUserSchema()) : {
      username: validateUsername,
    },
    initialValues: {
      image: null,
      clear_image: false,
      name: user?.name ?? '',
      email: user?.email ?? '',
      username: user?.username ?? '',
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    try {
      await queryHandler(editUser, values, form);
      showNotification({ message: 'Profile successfully updated.' });
      goBack();
    } catch (error) {
      const imgError = (error as any)?.json?.errors?.image?.[0] as string | undefined;
      if (imgError) errorMsg((error as any)?.json?.errors?.image?.[0]);
      console.error(error);
    }
  });

  const {
    imgProps, imgWrapperProps, uploadProps, clearProps, resetProps,
  } = useImageForm(form, user?.image_url);

  if (isPending) return <LoadingOverlay visible />;

  return (
    <Stack className={classes.frame} gap={0}>
      <form onSubmit={onSubmit}>
        <Group pb="xs" justify="center">
          <FileButton accept="image/png,image/jpeg" {...imgWrapperProps}>
            {(buttonProps) => (
              <Avatar
                size={100}
                classNames={{ image: classes.image }}
                color={primaryColor}
                {...imgProps}
                {...buttonProps}
              >
                {getUserLetters(form.values.name || user?.name)}
              </Avatar>
            )}
          </FileButton>
          <Stack className={classes.grow}>
            <FileButton accept="image/png,image/jpeg" {...uploadProps}>
              {(props) => (
                <Button
                  leftSection={<IconUpload size={16} strokeWidth={1.5} />}
                  {...props}
                >
                  Upload
                </Button>
              )}
            </FileButton>
            <Group>
              <Button
                className={classes.grow}
                leftSection={<IconEraser size={16} strokeWidth={1.5} />}
                {...clearProps}
              >
                Clear
              </Button>
              <Button
                className={classes.grow}
                leftSection={<IconRefresh size={16} strokeWidth={1.5} />}
                {...resetProps}
              >
                Reset
              </Button>
            </Group>
          </Stack>
        </Group>
        <TextInput
          required
          label="Name"
          placeholder="Name"
          leftSection={<IconUser size={16} strokeWidth={1.5} />}
          {...form.getInputProps('name')}
        />
        <TextInput
          required
          type="email"
          label="Email"
          placeholder="Email"
          leftSection={<IconAt size={16} strokeWidth={1.5} />}
          {...form.getInputProps('email')}
        />
        <TextInput
          required
          label="Username"
          placeholder="Username"
          description="Can only contain letters, numbers, dashes and underscores."
          leftSection={<IconLink size={16} strokeWidth={1.5} />}
          {...form.getInputProps('username')}
        />
        <Group justify="right" mt="md">
          <Button variant="subtle" onClick={goBack}>Cancel</Button>
          <Button type="submit" variant="outline">Save</Button>
        </Group>
      </form>
    </Stack>
  );
}
