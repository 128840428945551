import {
  Dispatch, ReducerAction, createContext, useContext, useReducer,
} from 'react';
import { CoinBasic } from '@/db/coin/schemas';

export type FilterData<T extends Record<string, unknown> = Record<string, unknown>> = {
  enabled: boolean;
  values: T;
  filterFn: (coins: Array<CoinBasic>, state: T) => Array<CoinBasic>;
};

type FiltersState = Record<string, FilterData>;

function reducer(
  state: FiltersState,
  action: {
    type?: 'individual', filterValue: FilterData, filterKey: string,
  } | {
    type: 'all', filterValue: Partial<FilterData>
  },
): FiltersState {
  if (action.type !== 'all') return { ...state, [action.filterKey]: action.filterValue };

  const newState = { ...state };
  Object.keys(state).forEach((key) => {
    newState[key] = { ...newState[key], ...action.filterValue };
  });
  return newState;
}

const FiltersContext = createContext<
[FiltersState, Dispatch<ReducerAction<typeof reducer>>] | null
>(null);
FiltersContext.displayName = 'FiltersContext';

export function FiltersProvider({ children }: { children: React.ReactNode }) {
  const context = useReducer(reducer, {});

  return (
    <FiltersContext.Provider value={context}>
      {children}
    </FiltersContext.Provider>
  );
}

export function useFilterContext() {
  const context = useContext(FiltersContext);
  if (!context) throw new Error('useFilterContext must be used within a FiltersProvider');
  return context;
}
