import {
  Avatar, Group, GroupProps, Stack, Text,
} from '@mantine/core';
import { IconCalendar, IconCoin } from '@tabler/icons-react';
import IconLabel from '@/components/IconLabel';
import { User } from '@/db/user/schema';
import getUserLetters from '@/helpers/getUserLetters';
import { compactNum } from '@/helpers/numbers';
import useThemeColor from '@/hooks/useThemeColor';

export default function UserCard({
  user, small = false, ...other
}: { user: User, small?: boolean } & Omit<GroupProps, 'wrap' | 'gap' | 'align'>) {
  const [primaryColor] = useThemeColor();

  const userLetters = getUserLetters(user.name);

  return (
    <Group wrap="nowrap" gap={0} align={small ? 'stretch' : 'center'} {...other}>
      <Avatar src={user.image_url} size={small ? 75 : 100} mr="md" color={primaryColor}>
        {userLetters}
      </Avatar>
      <Stack gap={0} justify="space-between" style={{ flexGrow: 1, minWidth: 0 }}>
        <Text size="xs" fw={700} c="dimmed">
          {user.username}
        </Text>
        <Text fw={500} size="xl" truncate={small || undefined}>
          {user.name}
        </Text>
        <Group wrap="nowrap" mt={3} gap={0}>
          <IconLabel
            icon={IconCoin}
            text={small ? compactNum(user.coins_count) : user.coins_count.toLocaleString()}
            style={{ width: 85, flexShrink: 1 }}
          />
          <IconLabel
            icon={IconCalendar}
            text={new Date(user.created_at).toLocaleDateString()}
            style={{ flexShrink: 0 }}
          />
        </Group>
      </Stack>
    </Group>
  );
}
