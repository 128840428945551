import { Text } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { useState } from 'react';

const fonts = ['Didact Gothic', 'Nunito Sans', 'League Spartan', 'Sen', 'Jost'];

export default function useFontCycle() {
  const [font, setfont] = useState(Math.floor(Math.random() * fonts.length));

  const nextFont = () => setfont(font + 1 >= fonts.length ? 0 : font + 1);
  const prevFont = () => setfont(font - 1 >= 0 ? font - 1 : fonts.length - 1);

  useHotkeys([['ArrowUp', nextFont], ['ArrowRight', nextFont], ['ArrowDown', prevFont], ['ArrowLeft', prevFont]]);

  const fontFamily = `${'' || fonts[font]},-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji`;
  const fontDisplay = (
    <div
      style={{
        position: 'fixed',
        zIndex: 100000,
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Text onClick={nextFont} fz={10} opacity={0.12}>
        Font:
        {' '}
        {fonts[font]}
      </Text>
    </div>
  );

  return { fontFamily, fontDisplay };
}
