import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { formatAuthData, userKey } from '@/db/auth';
import { CoinBasic, CoinDelete } from '@/db/coin/schemas';
import { PasswordConfirm, User } from '@/db/user/schema';
import $fetch from '@/helpers/$fetch';

async function deleteCoinFn(coin: CoinDelete): Promise<void> {
  await $fetch(`/api/coin/${coin.id}`, { method: 'DELETE' });
}

export function useDeleteCoin(options?: UseMutationOptions<void, Error, CoinDelete>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: deleteCoinFn,
    onSuccess: (res, sendCoin, context) => {
      queryClient.removeQueries({ queryKey: ['coins', { id: sendCoin.id }] });

      const coins = queryClient.getQueryData<Array<CoinBasic>>(['coins']);
      if (coins) {
        queryClient.setQueryData(['coins'], coins.filter((coin) => coin.id !== sendCoin.id));
      }

      const user = queryClient.getQueryData<User>(userKey);
      if (user) {
        user.coins_count -= 1;
        queryClient.setQueryData(userKey, user);
      }
      options?.onSuccess?.(res, sendCoin, context);
    },
  });
}

async function deleteCoinAllFn(data: PasswordConfirm) {
  const body = formatAuthData(data);
  body.append('_method', 'DELETE');
  await $fetch('/api/coin', { method: 'POST', body });
}

export function useDeleteCoinAll(options?: UseMutationOptions<void, Error, PasswordConfirm>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: deleteCoinAllFn,
    onSuccess: (...args) => {
      queryClient.removeQueries({ queryKey: ['coins'] });

      const user = queryClient.getQueryData<User>(userKey);
      if (user) {
        user.coins_count = 0;
        queryClient.setQueryData(userKey, user);
      }
      options?.onSuccess?.(...args);
    },
  });
}
