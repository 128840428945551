import { Box, Stack, Title } from '@mantine/core';
import CoinImage from '@/components/CoinImage';
import IconLabel from '@/components/IconLabel';
import { CoinDisplayField, coinDisplayFields } from '@/constants/coinFields';
import { CoinBasic } from '@/db/coin/schemas';
import getDisplayFieldData from '@/helpers/getDisplayFieldData';
import classes from './CoinPreview.module.css';

export default function CoinPreview({ coin }: { coin: CoinBasic | undefined }) {
  return (
    <Box p="md">
      <CoinImage src={coin?.thumb_url ?? null} className={classes.image} />
      <Title order={4} fs={!coin?.denomination ? 'italic' : undefined}>
        {coin?.denomination || 'Unnamed coin'}
      </Title>
      <Stack>
        {coin && Object.keys(coinDisplayFields).map((field) => {
          const { icon, text, unknown } = getDisplayFieldData(coin, field as CoinDisplayField);
          return <IconLabel icon={icon} text={text} key={field} italic={unknown} />;
        })}
      </Stack>
    </Box>
  );
}
