import { NavLink } from '@mantine/core';
import {
  IconBrush, IconChevronRight, IconCoin, IconUser,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';

export default function AllSettings() {
  return (
    <>
      <NavLink
        label="Theme"
        px={0}
        pt={0}
        leftSection={<IconBrush size={16} strokeWidth={1.5} />}
        rightSection={<IconChevronRight size={16} strokeWidth={1.5} />}
        component={Link}
        to="/settings/theme"
      />
      <NavLink
        label="Collection"
        px={0}
        leftSection={<IconCoin size={16} strokeWidth={1.5} />}
        rightSection={<IconChevronRight size={16} strokeWidth={1.5} />}
        component={Link}
        to="/settings/collection"
      />
      <NavLink
        label="Account"
        px={0}
        leftSection={<IconUser size={16} strokeWidth={1.5} />}
        rightSection={<IconChevronRight size={16} strokeWidth={1.5} />}
        component={Link}
        to="/settings/account"
      />
    </>
  );
}
