import { LoadingOverlay } from '@mantine/core';
import { ReactNode } from 'react';
import { useUser } from '@/db/auth';

export default function AuthLoader({ children }: { children: ReactNode }) {
  const { isPending } = useUser();

  if (isPending) return <LoadingOverlay visible />;

  return <>{children}</>;
}
