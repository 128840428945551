import { LoadingOverlay } from '@mantine/core';
import { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useCoin } from '@/db/coin/useCoin';

const CoinEditor = lazy(() => import('@/components/CoinEditor/CoinEditor'));

export default function EditCoin() {
  const { id } = useParams();

  const { data: coin, isPending } = useCoin(id);

  if (isPending) return <LoadingOverlay visible />;

  return (
    <Suspense fallback={<LoadingOverlay visible />}>
      <CoinEditor coin={coin} />
    </Suspense>
  );
}
