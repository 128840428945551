import { AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import Footer from '@/components/Shell/Footer';
import HeaderBar from '@/components/Shell/HeaderBar';
import { useUser } from '@/db/auth';

export default function Shell() {
  const { data: user } = useUser();
  const showBanner = user && !user.email_verified_at && window.location.pathname !== '/verify';

  return (
    <AppShell
      header={{ height: user && showBanner ? 95 : 50 }}
      footer={{ height: 50 }}
    >
      <HeaderBar />
      <Footer />
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
