import {
  Anchor, Box, Divider, Group, Stack, Title,
} from '@mantine/core';
import { IconChevronRight, IconX } from '@tabler/icons-react';
import { Fragment } from 'react';
import { Link, Outlet, useMatches } from 'react-router-dom';
import classes from './Settings.module.css';

export default function SettingsPage() {
  const matches = useMatches();
  const crumbs = matches.filter(
    ({ handle }) => handle
      && typeof handle === 'object'
      && 'crumb' in handle
      && typeof handle.crumb === 'string',
  ) as Array<(typeof matches)[number] & { handle: { crumb: string } }>;

  return (
    <Stack gap={0} className={classes.frame}>
      <Group wrap="nowrap" justify="space-between" m="md" mb="sm" align="center">
        <div>
          <Anchor component={Link} variant="text" to="/settings">
            <Title order={3} display="inline" c={crumbs.length ? 'dimmed' : undefined}>
              Settings
            </Title>
          </Anchor>
          {crumbs.map((match, index) => (
            <Fragment key={match.handle.crumb}>
              <IconChevronRight size={16} strokeWidth={1.5} className={classes.chevron} />
              <Anchor component={Link} variant="text" to={match.pathname}>
                <Title order={5} display="inline" c={index === crumbs.length - 1 ? undefined : 'dimmed'}>
                  {match.handle.crumb}
                </Title>
              </Anchor>
            </Fragment>
          ))}
        </div>
        <Anchor component={Link} variant="text" to="/" p="xs" m="-xs">
          <IconX size={20} strokeWidth={1.5} display="block" />
        </Anchor>
      </Group>
      <Divider mx="md" />
      <Box p="md" className={classes.scroll}>
        <Outlet />
      </Box>
    </Stack>
  );
}
