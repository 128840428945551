export function pad(num: number, places: number) {
  return String(num).padStart(places, '0');
}

export function isNumeric(val: string) {
  return !Number.isNaN(parseFloat(val)) && Number.isFinite(+val);
}

export function toDecimals(num: number, decimals: number) {
  const factor = 10 ** decimals;
  return Math.round((num + Number.EPSILON) * factor) / factor;
}

export function compactNum(value: number) {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);
}
