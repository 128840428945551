import { UnstyledButton } from '@mantine/core';
import { useCallback } from 'react';
import { useFilterContext } from '@/components/filters/FiltersContext';
import useThemeColor from '@/hooks/useThemeColor';

export default function FilterBar() {
  const [filters, dispatchFilters] = useFilterContext();

  const [primaryColor] = useThemeColor();

  const disableAllFilters = useCallback(() => {
    dispatchFilters({ type: 'all', filterValue: { enabled: false } });
  }, [dispatchFilters]);

  const activeCount = Object.values(filters).reduce((acc, val) => acc + +val.enabled, 0);

  return (
    <UnstyledButton
      px="md"
      bg={primaryColor}
      h={activeCount ? 45 : 0}
      style={{ overflow: 'hidden', transition: 'all .2s ease', flexShrink: 0 }}
      onClick={disableAllFilters}
    >
      Disable all filters (
      {activeCount}
      {' '}
      active)
    </UnstyledButton>
  );
}
