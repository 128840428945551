import { quickScore } from 'quick-score';
import { CoinDisplayField, coinDisplayFields } from '@/constants/coinFields';
import { CoinBasic } from '@/db/coin/schemas';
import getDisplayFieldData from '@/helpers/getDisplayFieldData';
import useCardFields from '@/hooks/useCardFields';

// Field count weight only matters for coin ranking; more matching fields makes a higher ranking.
// Position weight is exponetial; a start of field match matters a lot more than end of field.
// Coverage generally matters more than position.
// A matching name is more important than other matching fields.
// const weightFieldCount = (fieldScore: number) => fieldScore + 1;
// const weightPosition = (posScore: number) => posScore ** 2;
// const weightCoverage = (covScore: number) => covScore * 2;
// const weightName = (nameScore: number) => nameScore * 2;

// function getFieldScore(haystack: string, needle: string) {
//   const haystackLower = haystack.toLowerCase();
//   const needleLower = needle.toLowerCase();
//   const hastackLength = haystack.length;
//   const needleLength = needle.length;

//   const index = haystackLower.indexOf(needleLower);
//   if (index === -1) return 0;
//   const coverage = (
//     hastackLength - haystackLower.replaceAll(needleLower, '').length
//   ) / hastackLength;
//   const maxIndex = hastackLength - needleLength;
//   const positioning = (maxIndex - index) / maxIndex;

//   return weightFieldCount(weightPosition(positioning) + weightCoverage(coverage));
// }

function filterCoins(
  coins: Array<CoinBasic>,
  inTerm: string,
  cardFields: Array<CoinDisplayField>,
): Array<{ fields: Array<CoinDisplayField> } & CoinBasic> {
  const [firstTerm, ...terms] = inTerm.split(' ');
  const term = firstTerm.trim();

  const matches = coins
    .flatMap((coin) => {
      let score = quickScore(coin.denomination ?? '', term);
      const fields = [...new Set(
        (Object.keys(coinDisplayFields) as Array<CoinDisplayField>)
          .flatMap((field) => {
            const { text, unknown } = getDisplayFieldData(coin, field as CoinDisplayField);
            const fieldScore = quickScore(text, term);
            if (unknown || !fieldScore) return [];
            score += fieldScore;
            return [{ field, score: fieldScore }];
          })
          .sort((a, b) => b.score - a.score)
          .map(({ field }) => field)
          .concat(cardFields),
      )];

      if (score <= 0) return [];
      return [{ ...coin, fields, score }];
    })
    .sort((a, b) => b.score - a.score)
    .map(({ score, ...rest }) => rest);

  return terms.length ? filterCoins(matches, terms.join(' '), cardFields) : matches;
}

export default function useCoinFilter(coins: Array<CoinBasic>, term: string) {
  const [cardFields] = useCardFields();

  const termTrimmed = term.trim();

  if (termTrimmed === '') {
    return coins.map((coin) => ({ ...coin, fields: cardFields }));
  }

  return filterCoins(coins, termTrimmed, cardFields);
}
