import { Accordion, Checkbox, Group } from '@mantine/core';
import {
  ChangeEventHandler, ReactNode, createContext, useCallback, useContext, useEffect,
} from 'react';
import { FilterData, useFilterContext } from '@/components/filters/FiltersContext';
import { CoinDisplayField, coinDisplayFields } from '@/constants/coinFields';

const OpenFilterContext = createContext<null | string>(null);
OpenFilterContext.displayName = 'OpenFilterContext';
export { OpenFilterContext };

function useFilterState<
T extends Record<string, unknown>,
>(key: string, initialValues: FilterData<T>) {
  const [filters, dispatchFilters] = useFilterContext();

  useEffect(() => {
    if (!filters[key]) {
      dispatchFilters({
        filterKey: key,
        filterValue: initialValues as FilterData,
      });
    }
  }, [dispatchFilters, filters, initialValues, key]);

  const filter = filters[key] ?? initialValues;

  const setFilter = useCallback((partialFilter: Partial<FilterData<T>>) => {
    dispatchFilters({
      filterKey: key,
      filterValue: { ...filter, ...partialFilter } as FilterData,
    });
  }, [key, dispatchFilters, filter]);

  return [filter as typeof initialValues, setFilter] as const;
}

function useGenericFilter<
T extends Record<string, unknown>,
>(key: CoinDisplayField, initialValues: FilterData<T>, shouldEnable: (state: T) => boolean) {
  const [filter, setFilter] = useFilterState(key, initialValues);

  const setValue = useCallback(<K extends keyof T>(valueKey: K, newValue: T[K]) => {
    const newState = { ...filter.values, [valueKey]: newValue };

    setFilter({
      ...filter,
      values: newState,
      enabled: shouldEnable(newState),
    });
  }, [filter, setFilter, shouldEnable]);

  const setEnabled = useCallback((newValue: boolean) => {
    setFilter({ enabled: newValue });
  }, [setFilter]);

  return [[filter.values, setValue], [filter.enabled, setEnabled], key] as const;
}

export function createFilter<
T extends Record<string, unknown>,
>(
  key: CoinDisplayField,
  filterFn: FilterData<T>['filterFn'],
  initialState: T,
  shouldEnable: (state: T) => boolean,
) {
  const filterObj = {
    enabled: false,
    filterFn,
    values: initialState,
  };
  return () => useGenericFilter(key, filterObj, shouldEnable);
}

export function Filter({
  field,
  enabled,
  onChange,
  children,
}: {
  field: CoinDisplayField;
  enabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  children: ReactNode;
}) {
  const { label, icon: Icon } = coinDisplayFields[field];

  const openFilter = useContext(OpenFilterContext);
  const isOpen = openFilter === field;

  return (
    <Accordion.Item value={field} style={{ borderBottom: 'none' }}>
      <Group gap={0} wrap="nowrap">
        <Checkbox px={15} style={{ flex: '1 0 30px' }} checked={enabled} onChange={onChange} />
        <Accordion.Control icon={<Icon size={16} strokeWidth={1.5} />} pl="xs">
          {label}
        </Accordion.Control>
      </Group>

      <Accordion.Panel pl={50}>{isOpen ? children : null}</Accordion.Panel>
    </Accordion.Item>
  );
}
