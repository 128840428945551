import {
  Divider, LoadingOverlay, NavLink, PasswordInput, Stack, Text, Title,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconKey, IconTrash, IconX } from '@tabler/icons-react';
import { useCallback } from 'react';
import CoinDisplayFieldSelect from '@/components/CoinDisplayFieldSelect';
import DrawerMenu from '@/components/DrawerMenu';
import { CoinDisplayField } from '@/constants/coinFields';
import { useUser } from '@/db/auth';
import { useDeleteCoinAll } from '@/db/coin/useDeleteCoin';
import { PasswordConfirm, PasswordConfirmSchema } from '@/db/user/schema';
import { queryHandler } from '@/helpers/$fetch';
import useCardFields from '@/hooks/useCardFields';

export default function CollectionSettings() {
  const { data: user } = useUser();

  const [
    [fieldTop, fieldBottom], [setFieldTop, setFieldBottom],
  ] = useCardFields();

  const form = useForm<PasswordConfirm>({
    validate: import.meta.env.DEV ? zodResolver(PasswordConfirmSchema()) : undefined,
    initialValues: { password: '' },
  });

  const [isDeleteOpen, { open: openDelete, close: closeDelete }] = useDisclosure(false);
  const { mutateAsync: deleteAll, isPending: isDeleting } = useDeleteCoinAll();

  const closeOverlayAndClearField = useCallback(() => {
    closeDelete();
    form.reset();
  }, [closeDelete, form]);

  const doDeleteAll = form.onSubmit(async (values) => {
    try {
      await queryHandler(deleteAll, values, form);
      closeOverlayAndClearField();
      showNotification({
        message: 'All coins successfully deleted.',
      });
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <>
      <DrawerMenu
        label="Purge collection"
        message={(
          <>
            Please provide your password if you really want to delete all your coins.
            Deleted data cannot be recovered!
            <PasswordInput
              required
              placeholder="Password"
              leftSection={<IconKey size={16} strokeWidth={1.5} />}
              {...form.getInputProps('password')}
              mt="xs"
            />
            <LoadingOverlay visible={isDeleting} />
          </>
        )}
        opened={isDeleteOpen}
        onClose={closeOverlayAndClearField}
        buttons={[
          { text: 'Keep', icon: IconX, onClick: closeOverlayAndClearField },
          {
            text: 'Delete all coins', icon: IconTrash, color: 'red', onClick: doDeleteAll, closeOnClick: false,
          },
        ]}
      />

      Your collection contains
      {' '}
      {user?.coins_count || 'no'}
      {' '}
      coins.

      <Stack mt="lg" gap="sm">
        <div>
          <Title order={5} my={0}>Glance properties</Title>
          <Text size="xs" c="dimmed" mt={0}>
            Private properties like price or seller will fall
            back to default values when viewing other&apos;s collections.
          </Text>
        </div>
        <CoinDisplayFieldSelect
          value={fieldTop}
          onChange={(val: CoinDisplayField) => setFieldTop(val)}
        />
        <CoinDisplayFieldSelect
          value={fieldBottom}
          onChange={(val: CoinDisplayField) => setFieldBottom(val)}
        />
      </Stack>

      <Divider mt="md" />

      <NavLink
        label="Purge collection"
        description="This irreveribly deletes all coins in your collection."
        px={0}
        leftSection={<IconTrash size={16} strokeWidth={1.5} />}
        onClick={openDelete}
        style={{ color: 'var(--mantine-color-red-filled)' }}
        disabled={!user?.coins_count}
      />
    </>
  );
}
