import {
  Divider, LoadingOverlay, Stack, Text,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import CoinCard from '@/components/CoinCard';
import List from '@/components/List';
import UserCard from '@/components/UserCard';
import { useUser } from '@/db/user/useUser';
import useCardFields from '@/hooks/useCardFields';

export default function UserPage() {
  const { username } = useParams();
  const [fields] = useCardFields(true);

  const { data: user, isPending } = useUser(username);

  if (isPending) return <LoadingOverlay visible />;
  if (!user) return <Text c="red" p="md">{`User "${username}" not found`}</Text>;

  return (
    <Stack style={{ height: '100%' }} gap={0}>
      <UserCard user={user} p="md" />
      <Divider mx="md" />
      <List list={user.coins} propKeys={['id']} itemHeight="135%" minItemWidth={150}>
        { ({ item: coin }) => (
          <CoinCard
            coin={coin}
            key={coin.id}
            fields={fields}
          />
        )}
      </List>
    </Stack>
  );
}
