import {
  Checkbox, Group, Radio, RangeSlider, Text,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import { useCallback, useMemo } from 'react';
import { Filter, createFilter } from '@/components/filters/Filter';
import { CoinBasic } from '@/db/coin/schemas';
import { useCoins } from '@/db/coin/useCoin';
import { yearToBCE } from '@/helpers/dates';

type FilterState = {
  dateRange: [number, number],
  includeNoDate: boolean,
  onlyShowNoDate: boolean,
};

function filterFn(coins: Array<CoinBasic>, state: FilterState) {
  if (state.onlyShowNoDate) return coins.filter(({ minted }) => minted === null);

  const [minDate, maxDate] = state.dateRange;
  if (!Number.isFinite(minDate) && !Number.isFinite(maxDate) && state.includeNoDate) return coins;

  return coins.filter(({ minted, minted_end: mintedEnd }) => {
    if (minted === null) return state.includeNoDate;
    return minDate <= (mintedEnd ?? minted) && minted <= maxDate;
  });
}

const useFilter = createFilter<FilterState>(
  'date',
  filterFn,
  {
    dateRange: [-Infinity, Infinity],
    includeNoDate: false,
    onlyShowNoDate: false,
  },
  (state) => {
    const someDate = state.dateRange.some((v) => Number.isFinite(v));
    return someDate || state.onlyShowNoDate;
  },
);

function useDateRange() {
  const { data: coins } = useCoins();

  const dates = useMemo(
    () => {
      if (!coins?.length) return [1, new Date().getFullYear()];
      return coins
        .flatMap((coin) => [coin.minted, coin.minted_end])
        .filter((date): date is number => date !== null);
    },
    [coins],
  );

  const dateRange = useMemo(
    () => {
      const minDate = Math.min(...dates);
      const maxDate = Math.max(...dates);

      const stepSize = Math.floor((maxDate - minDate) / 5);
      const minMark = Math.ceil((minDate + (stepSize / 2)) / 10) * 10;
      const maxMark = Math.floor((maxDate - (stepSize / 2)) / 10) * 10;

      const marks = [
        { value: minMark, label: yearToBCE(minMark) },
        { value: maxMark, label: yearToBCE(maxMark) },
      ];
      if (minDate + stepSize < 0 && maxDate - stepSize > 0) {
        marks.push({ value: 1, label: '1 CE' });
      }

      return [minDate, maxDate, marks] as const;
    },
    [dates],
  );

  return dateRange;
}

export default function DateFilter() {
  const uuid = useId();

  const [[filter, setFilter], [enabled, setEnabled]] = useFilter();

  const [minDate, maxDate, marks] = useDateRange();

  const label = useCallback((val: number) => {
    const prefix = val > filter.dateRange[0] ? 'Till' : 'From';
    if (val < minDate || val > maxDate) return `${prefix} any`;
    return `${prefix} ${yearToBCE(val)}`;
  }, [filter.dateRange, maxDate, minDate]);

  const update = useCallback((val: [number, number]) => {
    let [min, max] = val;
    if (min === 0) min = 1;
    if (max === 0) max = 1;
    if (min < minDate) min = -Infinity;
    if (max > maxDate) max = Infinity;
    setFilter('dateRange', [min, max]);
  }, [maxDate, minDate, setFilter]);

  return (
    <Filter
      field="date"
      enabled={enabled}
      onChange={() => {
        setEnabled(!enabled);
      }}
    >
      <Checkbox
        label="N.D. only"
        mb="sm"
        checked={filter.onlyShowNoDate}
        onChange={() => {
          setFilter('onlyShowNoDate', !filter.onlyShowNoDate);
        }}
      />

      <Text size="sm" component="label" htmlFor={uuid} fw={500}>Only coins between</Text>
      <RangeSlider
        disabled={filter.onlyShowNoDate}
        id={uuid}
        mt="xs"
        mb="lg"
        min={minDate - 1 || -1}
        max={maxDate + 1}
        minRange={1}
        step={1}
        marks={marks}
        label={label}
        value={filter.dateRange}
        onChange={update}
      />

      <Radio.Group
        label="'N.D.' values"
        pt="sm"
        value={filter.includeNoDate ? 'include' : 'exclude'}
        onChange={() => {
          setFilter('includeNoDate', !filter.includeNoDate);
        }}
      >
        <Group pt="xs" gap="lg">
          <Radio value="exclude" label="Exclude" disabled={filter.onlyShowNoDate} />
          <Radio value="include" label="Include" disabled={filter.onlyShowNoDate} />
        </Group>
      </Radio.Group>
    </Filter>
  );
}
