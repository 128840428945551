import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@/assets/fonts/jost/jost.css';

import ReactDOM from 'react-dom/client';
import App from '@/App';
import $fetch from '@/helpers/$fetch';

$fetch('/api/auth/csrf-cookie');

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
