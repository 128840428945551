import { Anchor, Box, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

export default function HomePage() {
  return (
    <Box p="md">
      <Text size="xl" fw={500}>
        Welcome to Nummus
      </Text>
      <Text>
        Please
        {' '}
        <Anchor component={Link} to="/login">
          log in
        </Anchor>
        {' '}
        or
        {' '}
        <Anchor component={Link} to="/register">
          register
        </Anchor>
      </Text>
    </Box>
  );
}
