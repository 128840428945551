import {
  Badge, Box, Button, Group, LoadingOverlay, Stack, Text,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ZoomableCoinImage } from '@/components/CoinImage';
import IconLabel from '@/components/IconLabel';
import TextBox from '@/components/TextBox';
import { coinDisplayFields } from '@/constants/coinFields';
import { useUser } from '@/db/auth';
import { useCoin } from '@/db/coin/useCoin';
import { useDeleteCoin } from '@/db/coin/useDeleteCoin';
import { queryHandler } from '@/helpers/$fetch';
import { formatCoinDate } from '@/helpers/dates';
import getHttpStatus from '@/helpers/getHttpStatus';
import { toDecimals } from '@/helpers/numbers';
import useConfirm from '@/hooks/useConfirm';

export default function CoinPage() {
  const { id } = useParams();
  const { data: user } = useUser();
  const navigate = useNavigate();

  const { data: coin, isPending, error: coinError } = useCoin(id);

  const confirm = useConfirm();
  const { mutateAsync: deleteCoin, isPending: isDeleting } = useDeleteCoin();
  const confirmDeleteCoin = async () => {
    const confirmed = await confirm({
      label: 'Delete Coin?',
      message: 'Removed items are not recoverable. Do you still wish to delete this coin?',
      cancelText: 'Keep',
      confirmText: `Delete ${coin?.denomination ? ` "${coin.denomination}"` : ''}`,
      confirmIcon: IconTrash,
    });
    if (confirmed) {
      try {
        await queryHandler(deleteCoin, { id: +id! });
        showNotification({ message: `Coin ${coin?.denomination ? `"${coin.denomination}" ` : ''}deleted` });
        navigate('/');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const isOwnedByViewer = coin?.user_id === user?.id;

  if (isPending || isDeleting) return <LoadingOverlay visible />;

  if (coinError || !coin) {
    return (
      <Box p="lg">
        <Text>Something has gone wrong!</Text>
        <Text c="dimmed" size="xs">
          {getHttpStatus(coinError instanceof Response ? coinError.status : 0) || 'Unknown error'}
        </Text>
      </Box>
    );
  }

  return (
    <Box style={{ height: '100%', overflowY: 'auto', overflowX: 'clip' }} p="md">
      {isOwnedByViewer && (
        <Group mb="sm">
          <Button
            variant="default"
            leftSection={<IconEdit size={16} strokeWidth={1.5} />}
            component={Link}
            to={`/coin/${id}/edit`}
            style={{ flexGrow: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="outline"
            leftSection={<IconTrash size={16} strokeWidth={1.5} />}
            onClick={confirmDeleteCoin}
            color="red"
          >
            Delete
          </Button>
        </Group>
      )}
      <ZoomableCoinImage image_url={coin.image_url} />
      <Text size="xs">{coin.references}</Text>
      <Text size="xl" fw={500} c={coin.denomination ? undefined : 'dimmed'}>
        {coin.denomination || 'Unnamed Coin'}
      </Text>
      <Text>
        {`${coin.origin || 'Origin unknown'} (${coin.mint || 'Mint unknown'}), ${coin.authority || 'Authority unknown'}.`}
      </Text>
      <IconLabel
        icon={coinDisplayFields.date.icon}
        text={formatCoinDate(coin)}
      />
      <Group p="sm" justify="center">
        {coin.material?.split(',').map((item) => <Badge key={item}>{item}</Badge>)}
      </Group>
      <Stack>
        <IconLabel
          icon={coinDisplayFields.fineness.icon}
          text={(coin.fineness && `${toDecimals(coin.fineness, 3)}`) || 'Unmeasured'}
        />
        <IconLabel
          icon={coinDisplayFields.weight.icon}
          text={(coin.weight && `${toDecimals(coin.weight, 3)}g`) || 'Unweighted'}
        />
        <IconLabel
          icon={coinDisplayFields.diameter.icon}
          text={(coin.diameter && `${toDecimals(coin.diameter, 3)}mm`) || 'Unmeasured'}
        />
        <IconLabel
          icon={coinDisplayFields.die_axis.icon}
          text={(coin.die_axis && `${coin.die_axis}h`) || 'Unmeasured'}
        />
        <IconLabel
          icon={coinDisplayFields.grade.icon}
          text={`${coin.grade || 'Ungraded'} ${coin.grader ? ` (Graded by ${coin.grader})` : ''}`}
        />
      </Stack>
      <TextBox text={coin.description} />
      {isOwnedByViewer && (
        <>
          <Stack>
            <IconLabel
              icon={coinDisplayFields.seller.icon}
              text={coin.seller || 'Unknown'}
            />
            <IconLabel
              icon={coinDisplayFields.price.icon}
              text={(coin.price?.toLocaleString() || 'Unknown').toString()}
            />
            <IconLabel
              icon={coinDisplayFields.buy_date.icon}
              text={coin.buy_date ? new Date(coin.buy_date).toLocaleDateString() : 'Unknown'}
            />
          </Stack>
          <TextBox text={coin.private_notes} />
        </>
      )}
    </Box>
  );
}
