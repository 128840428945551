import { CoinDisplayField, coinDisplayFields } from '@/constants/coinFields';
import { CoinBasic } from '@/db/coin/schemas';
import { formatCoinDate } from '@/helpers/dates';
import { toDecimals } from '@/helpers/numbers';

export default function getDisplayFieldData(
  coin: CoinBasic,
  property: CoinDisplayField,
  sortable = false,
) {
  let text: string | null | undefined = '';
  let isUnknown = false;
  switch (property) {
    case 'date':
      text = formatCoinDate(coin, sortable);
      isUnknown = text === 'N.D.';
      break;
    case 'fineness':
      text = coin.fineness !== null ? `${toDecimals(coin.fineness, 3)}` : 'Unmeasured';
      isUnknown = coin.fineness === null;
      if (sortable) text = coin.fineness?.toString();
      break;
    case 'weight':
      text = coin.weight !== null ? `${toDecimals(coin.weight, 3)}g` : 'Unweighted';
      isUnknown = coin.weight === null;
      if (sortable) text = coin.weight?.toString();
      break;
    case 'diameter':
      text = coin.diameter !== null ? `${toDecimals(coin.diameter, 3)}mm` : 'Unmeasured';
      isUnknown = coin.diameter === null;
      if (sortable) text = coin.diameter?.toString();
      break;
    case 'die_axis':
      text = coin.die_axis !== null ? `${coin.die_axis}h` : 'Unmeasured';
      isUnknown = coin.die_axis === null;
      if (sortable) text = coin.die_axis?.toString();
      break;
    case 'material':
      text = coin.material?.replaceAll(',', ', ');
      if (sortable) text = coin.material?.split(',').sort().join(', ');
      break;
    case 'references':
      text = coin.references || `No ${coinDisplayFields.references.label}`;
      isUnknown = !coin.references;
      break;
    case 'buy_date':
      text = coin.buy_date ? new Date(coin.buy_date).toLocaleDateString() : '';
      if (sortable) text = coin.buy_date;
      break;
    default:
      text = coin[property]?.toString();
  }
  return {
    icon: coinDisplayFields[property].icon,
    text: text || `${coinDisplayFields[property].label} unknown`,
    unknown: isUnknown || !text,
  };
}
