import {
  Anchor, AppShell, Avatar, Box, Burger, Button, Group, NavLink, Text, Title, UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconChevronRight,
  IconLogout, IconMail, IconPencil, IconSettings, IconUser, IconUsers,
} from '@tabler/icons-react';
import { useCallback } from 'react';
import { Link, useLinkClickHandler, useLocation } from 'react-router-dom';
import DrawerMenu from '@/components/DrawerMenu';
import DarkModeSwitch from '@/components/Settings/DarkModeSwitch';
import UserCard from '@/components/UserCard';
import { useLogout, useUser } from '@/db/auth';
import { queryHandler } from '@/helpers/$fetch';
import getUserLetters from '@/helpers/getUserLetters';
import useConfirm from '@/hooks/useConfirm';
import useThemeColor from '@/hooks/useThemeColor';
import classes from './HeaderBar.module.css';

export default function HeaderBar() {
  const [primaryColor] = useThemeColor();

  const { data: user } = useUser();
  const location = useLocation();
  const { mutateAsync: logout } = useLogout();

  const showBanner = user && !user.email_verified_at && location.pathname !== '/verify';

  const confirm = useConfirm();
  const confirmLogout = useCallback(async () => {
    if (await confirm({
      label: 'Logout',
      message: 'Are you sure you want to log out?',
      cancelText: 'Stay logged in',
      confirmText: 'Log out',
      confirmIcon: IconLogout,
    })) {
      try {
        queryHandler(logout, null);
      } catch (error) {
        console.error(error);
      }
    }
  }, [confirm, logout]);

  const [isSideOpen, { close: closeSide, toggle: toggleSide }] = useDisclosure(false);
  const [isUserOpen, { open: openUser, close: closeUser }] = useDisclosure(false);

  const userLetters = getUserLetters(user?.name);

  const viewSettings = useLinkClickHandler<HTMLAnchorElement>('/settings');
  const viewUsers = useLinkClickHandler<HTMLAnchorElement>('/users');
  const viewProfile = useLinkClickHandler<HTMLAnchorElement>(`/users/${user?.username}`);
  const editProfile = useLinkClickHandler<HTMLAnchorElement>('/settings/account/profile');

  const pagesButtons = [
    {
      text: 'Users',
      icon: IconUsers,
      onClick: viewUsers,
    },
    {
      text: 'Settings',
      icon: IconSettings,
      onClick: viewSettings,
    },
  ];

  const userButtons = [
    {
      text: 'My profile',
      icon: IconUser,
      onClick: viewProfile,
    },
    {
      text: 'Edit profile',
      icon: IconPencil,
      onClick: editProfile,
    },
    {
      text: 'Log out',
      icon: IconLogout,
      color: 'red',
      onClick: confirmLogout,
    },
  ];

  const verifyEmailBanner = (
    <NavLink
      label="Please verify your email"
      p="xs"
      leftSection={<IconMail size={16} strokeWidth={1.5} />}
      rightSection={<IconChevronRight size={16} strokeWidth={1.5} />}
      component={Link}
      to="/verify"
      bg="red.8"
      c="white"
    />
  );

  if (!user) {
    return (
      <AppShell.Header>
        <Group wrap="nowrap" justify="space-between" px="md" py="sm" h="100%">
          <Anchor component={Link} to="/">
            <Title order={3}>Nummus</Title>
          </Anchor>
          <Button component={Link} to="/login" variant="outline">Log In</Button>
        </Group>
      </AppShell.Header>
    );
  }

  return (
    <AppShell.Header>
      <Group
        wrap="nowrap"
        justify="space-between"
        px="md"
        py="sm"
        gap={0}
        h={`calc(100% - ${showBanner ? 45 : 0}px)`}
      >
        <Burger
          px="md"
          py="xs"
          ml="-md"
          w="auto"
          h={30}
          opened={isSideOpen}
          onClick={toggleSide}
          className={classes.burger}
        />
        <DrawerMenu
          header={(
            <Box p="md">
              <Title order={3}>Nummus</Title>
              <Text c="dimmed">v0.early</Text>
              <DarkModeSwitch mt="md" />
            </Box>
          )}
          opened={isSideOpen}
          onClose={closeSide}
          buttons={pagesButtons}
        />
        <UnstyledButton px="md" py="xs" mr="-md" onClick={openUser}>
          <Avatar src={user.image_url} size={30} color={primaryColor}>{userLetters}</Avatar>
        </UnstyledButton>
        <DrawerMenu
          header={<UserCard user={user} p="md" />}
          opened={isUserOpen}
          onClose={closeUser}
          buttons={userButtons}
        />
      </Group>
      {showBanner && verifyEmailBanner}
    </AppShell.Header>
  );
}
