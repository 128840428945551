import {
  Divider, LoadingOverlay, NavLink, PasswordInput, useMantineTheme,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconChevronRight, IconKey, IconLock, IconLogout, IconPencil, IconTrash, IconX,
} from '@tabler/icons-react';
import { useCallback } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import DrawerMenu from '@/components/DrawerMenu';
import UserCard from '@/components/UserCard';
import {
  useDeleteUser, useLogout, useLogoutOtherDevices, useUser,
} from '@/db/auth';
import { PasswordConfirm, PasswordConfirmSchema } from '@/db/user/schema';
import { queryHandler } from '@/helpers/$fetch';

export default function AccountSettings() {
  const { data: user } = useUser();

  const navigate = useNavigate();

  const { breakpoints } = useMantineTheme();
  const isSmall = useMediaQuery(
    `(max-width: ${breakpoints.xs}px)`,
    false,
    { getInitialValueInEffect: false },
  );

  const form = useForm<PasswordConfirm>({
    validate: import.meta.env.DEV ? zodResolver(PasswordConfirmSchema()) : undefined,
    initialValues: { password: '' },
  });

  const [isThisOpen, { open: openThis, close: closeThis }] = useDisclosure(false);
  const { mutateAsync: logout } = useLogout();
  const [isOtherOpen, { open: openOther, close: closeOther }] = useDisclosure(false);
  const { mutateAsync: logoutOtherDevices, isPending: isOtherLoading } = useLogoutOtherDevices();
  const [isDeleteOpen, { open: openDelete, close: closeDelete }] = useDisclosure(false);
  const { mutateAsync: deleteUser, isPending: isDeleteLoading } = useDeleteUser();

  const closeOverlayAndClearField = useCallback(() => {
    closeOther();
    closeDelete();
    form.reset();
  }, [closeDelete, closeOther, form]);

  const doLogoutOther = form.onSubmit(async (values) => {
    try {
      await queryHandler(logoutOtherDevices, values, form);
      closeOverlayAndClearField();
      showNotification({
        message: 'All other devices successfully logged out.',
      });
    } catch (error) {
      console.error(error);
    }
  });

  const doDeleteUser = form.onSubmit(async (values) => {
    try {
      await queryHandler(deleteUser, values, form);
      navigate('/');
      showNotification({
        message: 'Account successfully deleted.',
      });
    } catch (error) {
      console.error(error);
    }
  });

  const doLogoutThis = async () => {
    try {
      await queryHandler(logout, null);
    } catch (error) {
      console.error(error);
    }
  };

  if (!user) return <Navigate to="/" />;

  return (
    <>
      <DrawerMenu
        label="Log out"
        message="Are you sure you want to log out?"
        opened={isThisOpen}
        onClose={closeThis}
        buttons={[
          { text: 'Stay logged in', icon: IconX, onClick: closeThis },
          {
            text: 'Log out', icon: IconLogout, color: 'red', onClick: doLogoutThis,
          },
        ]}
      />
      <DrawerMenu
        label="Log out other devices"
        message={(
          <>
            Please provide your password if you really want to log out all other devices.
            <PasswordInput
              required
              placeholder="Password"
              leftSection={<IconKey size={16} strokeWidth={1.5} />}
              {...form.getInputProps('password')}
              mt="xs"
            />
            <LoadingOverlay visible={isOtherLoading} />
          </>
        )}
        opened={isOtherOpen}
        onClose={closeOverlayAndClearField}
        buttons={[
          { text: 'Cancel', icon: IconX, onClick: closeOverlayAndClearField },
          {
            text: 'Log out all other devices', icon: IconLogout, color: 'red', onClick: doLogoutOther, closeOnClick: false,
          },
        ]}
      />
      <DrawerMenu
        label="Delete account"
        message={(
          <>
            Please provide your password if you really want to delete your account.
            Deleted data cannot be recovered!
            <PasswordInput
              required
              placeholder="Password"
              leftSection={<IconKey size={16} strokeWidth={1.5} />}
              {...form.getInputProps('password')}
              mt="xs"
            />
            <LoadingOverlay visible={isDeleteLoading} />
          </>
        )}
        opened={isDeleteOpen}
        onClose={closeOverlayAndClearField}
        buttons={[
          { text: 'Cancel', icon: IconX, onClick: closeOverlayAndClearField },
          {
            text: 'Delete my account permanently', icon: IconTrash, color: 'red', onClick: doDeleteUser, closeOnClick: false,
          },
        ]}
      />

      <UserCard user={user} mb="xs" small={isSmall} />

      <NavLink
        label="Edit profile"
        px={0}
        leftSection={<IconPencil size={16} strokeWidth={1.5} />}
        rightSection={<IconChevronRight size={16} strokeWidth={1.5} />}
        component={Link}
        to="/settings/account/profile"
      />

      <Divider />

      <NavLink
        label="Log out"
        px={0}
        leftSection={<IconLogout size={16} strokeWidth={1.5} />}
        onClick={openThis}
      />
      <NavLink
        label="Log out other devices"
        description="All other devices will have to log in again. Helpful if you think your account might be compromised."
        px={0}
        leftSection={<IconLock size={16} strokeWidth={1.5} />}
        onClick={openOther}
      />

      <Divider />

      <NavLink
        label="Delete account"
        description="This irreveribly deletes your account and all associated coins."
        px={0}
        leftSection={<IconTrash size={16} strokeWidth={1.5} />}
        onClick={openDelete}
        style={{ color: 'var(--mantine-color-red-filled)' }}
      />
    </>
  );
}
