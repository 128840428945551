export default function getUserLetters(name: string | undefined): string {
  if (!name) return '';

  const nameParts = name.split(' ').filter((e) => !!e);

  let [[userLetters]] = nameParts;
  if (nameParts.length > 1) userLetters += nameParts.slice(-1)[0][0];

  return userLetters;
}
