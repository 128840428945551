import { CoinBasic } from '@/db/coin/schemas';
import { pad } from '@/helpers/numbers';

export function mintedToInt(year: number | null, isBCE: boolean) {
  if (!year) return null; // Also for year 0!
  return isBCE ? -year : year;
}

export const yearToBCE = (year: number) => `${Math.abs(year)}\xa0${year < 0 ? 'B' : ''}CE`;

const yearToSortable = (year: number) => `${year < 0 ? '' : '_'}${pad(year < 0 ? (9999 + year) : year, 4)}`;

export function formatCoinDate(coin: CoinBasic, sortable = false) {
  if (coin.minted === null) { return 'N.D.'; }
  const formatYear = sortable ? yearToSortable : yearToBCE;
  let res = formatYear(coin.minted);
  if (coin.minted_end) res += ` - ${formatYear(coin.minted_end)}`;
  if (coin.no_date) res = `N.D. (${res})`;
  return res;
}

export function getYearMonthDay(date: Date) {
  const offset = date.getTimezoneOffset();
  const normalizedDate = new Date(date.getTime() - (offset * 60 * 1000));
  return normalizedDate.toISOString().split('T')[0];
}
