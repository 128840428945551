import {
  IconAtom,
  IconBuildingBank,
  IconBuildingStore,
  IconCalendar,
  IconCalendarOff,
  IconCertificate,
  IconCircleOff,
  IconClipboardData,
  IconClock,
  IconCrown,
  IconCurrencyEuro,
  IconDeviceSpeaker,
  IconHash,
  IconMapPin,
  IconPercentage,
  IconScale,
  IconShoppingCart,
  IconTag,
} from '@tabler/icons-react';
import { Coin } from '@/db/coin/schemas';
import createDefineFields from '@/helpers/defineFields';

const defineCoinFields = createDefineFields<Coin>();

export const {
  dataFields: coinFields,
  displayFields: coinDisplayFields,
  privateFields: coinPrivateFields,
  defaultFields: coinDefaultFields,
} = defineCoinFields(
  {
    id: undefined,
    user_id: undefined,
    created_at: undefined,
    updated_at: undefined,
    image_url: undefined,
    thumb_url: undefined,
    denomination: {
      label: 'Denomination',
      icon: IconTag,
      display: 'public',
    },
    origin: {
      label: 'Origin',
      icon: IconMapPin,
      display: 'public',
    },
    authority: {
      label: 'Authority',
      icon: IconCrown,
      display: 'public',
    },
    mint: {
      label: 'Mint',
      icon: IconBuildingBank,
      display: 'public',
    },
    no_date: {
      label: 'No Date',
      icon: IconCalendarOff,
      display: false,
    },
    minted: {
      label: 'Year',
      icon: IconCalendar,
      display: false,
    },
    minted_end: {
      label: 'Year',
      icon: IconCalendar,
      display: false,
    },
    material: {
      label: 'Material',
      icon: IconAtom,
      display: 'public',
    },
    fineness: {
      label: 'Fineness',
      icon: IconPercentage,
      display: 'public',
    },
    weight: {
      label: 'Weight (g)',
      icon: IconScale,
      display: 'public',
    },
    diameter: {
      label: 'Diameter (mm)',
      icon: IconCircleOff,
      display: 'public',
    },
    die_axis: {
      label: 'Die Axis',
      icon: IconClock,
      display: 'public',
    },
    grade: {
      label: 'Grade',
      icon: IconDeviceSpeaker,
      display: 'public',
    },
    grader: {
      label: 'Grader',
      icon: IconClipboardData,
      display: 'public',
    },
    references: {
      label: 'References',
      icon: IconHash,
      display: 'public',
    },
    description: {
      label: 'Description',
      icon: IconHash,
      display: false,
    },
    seller: {
      label: 'Seller',
      icon: IconBuildingStore,
      display: 'private',
    },
    price: {
      label: 'Price (€)',
      icon: IconCurrencyEuro,
      display: 'private',
    },
    buy_date: {
      label: 'Buy date',
      icon: IconShoppingCart,
      display: 'private',
    },
    cert_num: {
      label: 'Cert #',
      icon: IconCertificate,
      display: 'private',
    },
    private_notes: {
      label: 'Notes',
      icon: IconHash,
      display: false,
    },
  },
  {
    date: {
      label: 'Date',
      icon: IconCalendar,
      display: 'public',
    },
  },
  {
    display: ['date', 'origin'],
    sort: 'denomination',
  },
);

export type CoinField = keyof typeof coinFields;
export type CoinDisplayField = keyof typeof coinDisplayFields;
