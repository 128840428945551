import {
  AppShell, Group, Indicator, Stack, Text, UnstyledButton,
} from '@mantine/core';
import {
  IconCirclePlus, IconCoin, IconFilter, IconReportAnalytics, Icon as TablerIcon,
} from '@tabler/icons-react';
import clsx from 'clsx';
import {
  NavLink, useMatch, useResolvedPath,
} from 'react-router-dom';
import { useFilterContext } from '@/components/filters/FiltersContext';
import { useUser } from '@/db/auth';
import classes from './Footer.module.css';

function NavButton({
  icon: Icon, text, to, exact = false, indicator = false,
}: { icon: TablerIcon; text: string; to: string; exact?: boolean; indicator?: boolean }) {
  const { pathname } = useResolvedPath(to);
  const match = useMatch({ path: pathname, end: exact });

  return (
    <UnstyledButton component={NavLink} to={to} className={classes.link}>
      <Indicator disabled={!indicator}>
        <Stack gap={0} align="center">
          <Icon size={18} strokeWidth={1.5} className={match ? classes.active : classes.inactive} />
          <Text size="xs" pt={1} className={clsx({ [classes.active]: match })}>{text}</Text>
        </Stack>
      </Indicator>
    </UnstyledButton>
  );
}

export default function Footer() {
  const { data: user } = useUser();
  const [filters] = useFilterContext();
  const filtersActive = Object.values(filters).some(({ enabled }) => enabled);

  return (user ?? null) && (
    <AppShell.Footer className={classes.navBar}>
      <Group gap={0} grow className={classes.fullHeight}>
        <NavButton
          icon={IconCoin}
          text="Collection"
          to="/"
          exact
        />
        <NavButton
          icon={IconFilter}
          text="Filters"
          to="/filters"
          indicator={!!filtersActive}
        />
        <NavButton
          icon={IconReportAnalytics}
          text="Statistics"
          to="/statistics"
        />
        <NavButton
          icon={IconCirclePlus}
          text="New"
          to="/coin/new"
        />
      </Group>
    </AppShell.Footer>
  );
}
