import { Box, Group, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import {
  CSSProperties, ComponentType, HTMLProps, forwardRef, memo,
} from 'react';
import { FixedSizeList } from 'react-window';

const padding = 16;

function useLayout(
  width: number,
  minItemWidth: number | undefined,
  itemHeight: number | `${number}%`,
  cellCount: number,
) {
  const usableWidth = Math.max(width - (padding * 2), 0);
  let colCount = 1;
  if (minItemWidth) {
    const availableWidth = Math.max(usableWidth - minItemWidth, 0);
    const extraCardMinWidth = minItemWidth + padding;
    colCount += Math.floor(availableWidth / extraCardMinWidth);
  }
  const rowCount = Math.ceil(cellCount / colCount);

  let rowHeight = padding;
  if (typeof itemHeight === 'number') {
    rowHeight += itemHeight;
  } else {
    const gapsTotalWidth = (colCount - 1) * padding;
    const itemWidth = (usableWidth - gapsTotalWidth) / colCount;
    const percentageAsFactor = +itemHeight.slice(0, -1) / 100;
    rowHeight += itemWidth * percentageAsFactor;
  }

  return { colCount, rowCount, rowHeight };
}

type ItemKey<T> = keyof T & string;
type PropKeys<T> = Array<ItemKey<T>>;

function keyFromItem<T>(item: T, propKeys: PropKeys<T>) {
  const values = propKeys.map((prop) => item[prop]);
  return values.join('|');
}

function useRowKey<T>(colCount: number, list: Array<T>, propKeys: PropKeys<T>) {
  return function itemKey(rowIndex: number) {
    const rowKeys: Array<string> = [];
    for (let colIndex = 0; colIndex < colCount; colIndex += 1) {
      const itemIndex = (colCount * rowIndex) + colIndex;
      if (itemIndex > list.length - 1) return '';
      rowKeys.push(keyFromItem(list[itemIndex], propKeys));
    }
    return rowKeys.join('|');
  };
}

function useRow<T>(colCount: number, list: Array<T>, propKeys: PropKeys<T>, flushTop: boolean) {
  const topExtra = flushTop ? 0 : padding;
  const finalRowIndex = Math.ceil(list.length / colCount) - 1;

  return function Row({ index: rowIndex, style, data: ItemTemplate }: {
    index: number,
    style: CSSProperties,
    data: ComponentType<{ item: T, index: number }>
  }) {
    const isLastRow = rowIndex === finalRowIndex;

    const adjustedStyle: CSSProperties = {
      ...style,
      top: +style.top! + topExtra,
      height: isLastRow ? +style.height! - padding : style.height,
    };

    return (
      <Group
        align="stretch"
        grow
        gap={padding}
        px={padding}
        pb={isLastRow ? 0 : padding}
        style={adjustedStyle}
      >
        {[...Array(colCount)].map(
          (e, colIndex) => {
            const itemIndex = (colCount * rowIndex) + colIndex;

            if (itemIndex > list.length - 1) return <div key={`${itemIndex} (empty)`} />;
            return (
              <ItemTemplate
                key={keyFromItem(list[itemIndex], propKeys)}
                item={list[itemIndex]}
                index={itemIndex}
              />
            );
          },
        )}
      </Group>
    );
  };
}

function useInner(flushTop: boolean) {
  const paddingTop = flushTop ? 0 : padding;

  return forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & { style: CSSProperties }>(
    ({ style, ...rest }, ref) => {
      const adjustedStyle: CSSProperties = {
        ...style,
        height: +style.height! - padding,
        boxSizing: 'content-box',
        paddingTop,
        paddingBottom: padding,
      };

      return <div ref={ref} style={adjustedStyle} {...rest} />;
    },
  );
}

function List<T>({
  list, propKeys, itemHeight, flushTop = false, minItemWidth, children: template,
}: {
  list: Array<T>,
  propKeys: PropKeys<T>,
  itemHeight: number | `${number}%`,
  flushTop?: boolean,
  minItemWidth?: number,
  children: Parameters<ReturnType<typeof useRow<T>>>[0]['data']
}) {
  const { ref, width, height } = useElementSize();
  const { colCount, rowCount, rowHeight } = useLayout(width, minItemWidth, itemHeight, list.length);
  const Inner = useInner(flushTop);
  const Row = useRow(colCount, list, propKeys, flushTop);
  const rowKey = useRowKey(colCount, list, propKeys);

  if (!list.length) return <Text mx="md" ta="center" c="dimmed" fs="italic">No results</Text>;
  return (
    <Box ref={ref} style={{ flexGrow: 1, minHeight: 0 }}>
      <FixedSizeList
        width={width}
        height={height}
        itemCount={rowCount}
        itemSize={rowHeight}
        innerElementType={Inner}
        itemData={template}
        itemKey={rowKey}
      >
        {Row}
      </FixedSizeList>
    </Box>
  );
}

const typedMemo: <T extends (...args: any[])=> JSX.Element, P extends Parameters<T>[0]>(
  c: T, ef?: (a: P, b: P) => boolean
) => T = memo;

export default typedMemo(List, (a, b) => {
  if (a.list.length !== b.list.length) return false;
  if (a.propKeys.join('') !== b.propKeys.join('')) return false;

  return a.list.every((item, index) => {
    const first = item as Record<string, unknown>;
    const second = b.list[index] as Record<string, unknown>;
    return a.propKeys.every((propKey: string) => {
      if (first[propKey] !== second[propKey]) return false;
      return true;
    });
  });
});
