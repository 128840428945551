import {
  Alert,
  Anchor, Button, Indicator, Stack, Text,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck, IconExclamationCircle, IconMail, IconX,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useUser, userKey } from '@/db/auth';
import $fetch from '@/helpers/$fetch';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { data: user } = useUser();
  const [error, setError] = useState<any>(null);
  const [sendState, setSendState] = useState('unsend');
  const queryClient = useQueryClient();

  const VerifyParamsSchema = z.object({
    expires: z.coerce.number().int().min(0),
    hash: z.string(),
    id: z.coerce.number().int().min(0),
    signature: z.string(),
  });
  const parsed = VerifyParamsSchema.safeParse(params);

  const resendMail = () => {
    if (sendState !== 'unsend') return;
    setSendState('sending');
    $fetch('api/auth/email/verification-notification', { method: 'POST' }).then(() => {
      setSendState('sent');
    }).catch(async (err) => {
      setSendState('unsend');
      if (err instanceof Response) {
        setError(await err.json());
      } else {
        setError(err);
      }
    });
  };

  useEffect(() => {
    if (user?.email_verified_at || !parsed.success || parsed.data.id !== user?.id) return;
    const newParams = new URLSearchParams({
      expires: parsed.data.expires.toString(),
      signature: parsed.data.signature,
    });
    $fetch(`/api/auth/email/verify/${parsed.data.id}/${parsed.data.hash}?${newParams}`)
      .then(() => {
        showNotification({ message: 'Email successfully verified' });
        queryClient.invalidateQueries({ queryKey: userKey });
      })
      .catch(async (err) => { setError(await err.json()); });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const positive = (
    <>
      <Alert
        icon={<IconExclamationCircle size={16} strokeWidth={1.5} />}
        title={error?.message ?? error?.toString() ?? error}
        color="red"
        radius="md"
      >
        Please try again.
      </Alert>
      <Button mt="lg" onClick={resendMail} disabled={sendState === 'sent'} loading={sendState === 'sending'}>
        { sendState === 'sent' ? 'Verification mail send' : 'Resend verification email' }
      </Button>
    </>
  );

  const negative = (
    <>
      <Text ta="center">A verification email has been send to you.</Text>
      <Text size="sm" c="dimmed" ta="center" mt="lg">
        Don&apos;t forget to check your spam folder!
        <br />
        {sendState === 'sent' ? 'Email send' : (
          <>
            Still can&apos;t find it?
            {' '}
            <Anchor onClick={resendMail} c={sendState === 'unsend' ? undefined : 'dimmed'}>Send me another</Anchor>
          </>
        )}
        .
      </Text>
    </>
  );

  const info = error ? positive : negative;

  return (
    <Stack align="center" mt="lg" gap="xs" p="md">
      <Indicator
        size={32}
        offset={8}
        color={error ? 'red' : 'lime'}
        position="middle-end"
        label={error
          ? <IconX size={26} strokeWidth={2.5} />
          : <IconCheck size={26} strokeWidth={2.5} />}
        styles={{ indicator: { padding: 0 } }}
        disabled={!error && !user?.email_verified_at}
      >
        <IconMail size={100} strokeWidth={1.5} style={{ marginBottom: 8 }} />
      </Indicator>
      { !user || !!user.email_verified_at
        ? 'Your email is verified.'
        : info}
    </Stack>
  );
}
