import {
  CSSProperties,
  DefaultMantineColor, Divider, Drawer, NavLink, Stack, Text, getThemeColor, useMantineTheme,
} from '@mantine/core';
import { Icon as TablerIcon } from '@tabler/icons-react';
import { MouseEventHandler, ReactNode, useCallback } from 'react';
import classes from './DrawerMenu.module.css';

export function MenuItem({
  text, icon: Icon, color, onClick, onClose, closeOnClick = true,
}: {
  text: string,
  icon: TablerIcon,
  color?: DefaultMantineColor | 'dimmed',
  onClick: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | (() => void),
  onClose: () => void,
  closeOnClick?: boolean
}) {
  const theme = useMantineTheme();

  const onClickAndClose = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    onClick(event);
    if (closeOnClick) onClose();
  }, [closeOnClick, onClick, onClose]);

  return (
    <NavLink
      key={text}
      c={color ? getThemeColor(color, theme) : undefined}
      leftSection={<Icon size={16} strokeWidth={1.5} />}
      label={text}
      onClick={onClickAndClose}
    />
  );
}

export default function DrawerMenu({
  label, message, header, buttons, opened, onClose,
}: {
  label?: string;
  message?: ReactNode;
  header?: ReactNode;
  buttons: Array<Omit<Parameters<typeof MenuItem>[0], 'onClose'>>;
  opened: boolean;
  onClose: () => void;
}) {
  const textHeader = (
    <Stack p="xl" gap="sm">
      {label && <Text p={0}>{label}</Text>}
      {message && <Text p={0} c="dimmed" size="sm">{message}</Text>}
    </Stack>
  );

  return (
    <Drawer
      position="bottom"
      size="auto"
      withCloseButton={false}
      opened={opened}
      onClose={onClose}
      padding={0}
      classNames={{ content: classes.drawer }}
    >
      {(header || label || message) && (
        <>
          <div
            className={classes.header}
            style={{ '--buttonCount': buttons.length } as CSSProperties}
          >
            {header || textHeader}
          </div>
          <Divider />
        </>
      )}
      {buttons.map((e) => ({ ...e, onClose })).map(MenuItem)}
    </Drawer>
  );
}
