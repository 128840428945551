import { Icon as TablerIcon } from '@tabler/icons-react';
import { HTMLAttributes } from 'react';
import classes from './IconLabel.module.css';

export default function IconLabel({
  icon: Icon,
  text,
  italic,
  doubleLine,
  ...other
}: {
  icon: TablerIcon,
  text: string,
  italic?: boolean,
  doubleLine?: boolean,
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classes.wrapper} {...other}>
      <Icon className={classes.icon} size={16} strokeWidth={1.5} />
      <span className={classes.text} style={{ fontStyle: italic ? 'italic' : '', height: doubleLine ? '3.1em' : 'auto' }}>{text}</span>
    </div>
  );
}
