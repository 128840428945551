import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { User, UserSchema } from '@/db/user/schema';
import $fetch, { parseIfDev } from '@/helpers/$fetch';
import { CoinBasicSchema } from '../coin/schemas';

async function fetchUser(username: string) {
  const res = await $fetch(`/api/user/${username}`);
  return parseIfDev(res, () => UserSchema().extend({
    coins: z.array(CoinBasicSchema()),
  }));
}

export function useUser(username: string | undefined) {
  return useQuery({
    queryKey: ['user', { username }],
    queryFn: () => fetchUser(username!),
    enabled: !!username,
  });
}

async function fetchUsers(): Promise<Array<User>> {
  const res = await $fetch('/api/user');
  return parseIfDev(res, () => z.array(UserSchema()));
}

export function useUsers() {
  return useQuery({ queryKey: ['users'], queryFn: fetchUsers });
}
