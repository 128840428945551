import { Group, MultiSelect, Radio } from '@mantine/core';
import { useMemo } from 'react';
import { Filter, createFilter } from '@/components/filters/Filter';
import { coinDisplayFields } from '@/constants/coinFields';
import { CoinBasic } from '@/db/coin/schemas';
import { useCoins } from '@/db/coin/useCoin';

type FilterState = {
  filterValue: Array<string>;
  matchType: 'any' | 'all' | 'exact';
};

function filterFn(coins: Array<CoinBasic>, state: FilterState) {
  const containsAllMaterials = (coin: CoinBasic) => state.filterValue.every(
    (material) => coin.material?.includes(material),
  );
  switch (state.matchType) {
    case 'any':
      return coins.filter((coin) => state.filterValue.some(
        (material) => coin.material?.includes(material),
      ));
    case 'all':
      return coins.filter(containsAllMaterials);
    case 'exact':
      return coins.filter((coin) => {
        const coinMaterialArray = coin.material?.split(',') ?? [];
        const isSameLength = coinMaterialArray.length === state.filterValue.length;
        return isSameLength && containsAllMaterials(coin);
      });
    default:
      return coins;
  }
}

const useFilter = createFilter<FilterState>(
  'material',
  filterFn,
  {
    filterValue: [],
    matchType: 'any',
  },
  ({ filterValue: materials }) => !!materials.length,
);

function useMaterialList() {
  const { data: coins } = useCoins();

  const materialList = useMemo(() => {
    const allMaterials = coins?.flatMap((coin) => coin.material?.split(',') ?? []);
    const uniqueMaterials = [...new Set(allMaterials)];

    return uniqueMaterials;
  }, [coins]);

  return materialList;
}

export default function MaterialFilter() {
  const [[filter, setFilter], [enabled, setEnabled], fieldName] = useFilter();

  const data = useMaterialList();

  const { label, icon: Icon } = coinDisplayFields.material;

  return (
    <Filter
      field={fieldName}
      enabled={enabled}
      onChange={() => setEnabled(!enabled)}
    >
      <MultiSelect
        label={label}
        placeholder={label}
        leftSection={<Icon size={16} strokeWidth={1.5} />}
        searchable
        data={data}
        value={filter.filterValue}
        onChange={(value) => setFilter('filterValue', value)}
      />

      <Radio.Group
        label="Material must contain"
        pt="sm"
        value={filter.matchType}
        onChange={(value) => setFilter('matchType', value as FilterState['matchType'])}
      >
        <Group pt="xs" gap="lg">
          <Radio value="any" label="Any" />
          <Radio value="all" label="All" />
          <Radio value="exact" label="Exact" />
        </Group>
      </Radio.Group>
    </Filter>
  );
}
