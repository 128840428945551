import { DefaultMantineColor } from '@mantine/core';
import { Icon, IconCheck, IconX } from '@tabler/icons-react';
import {
  ReactNode, createContext, useCallback, useContext, useState,
} from 'react';
import DrawerMenu from '@/components/DrawerMenu';

type ConfirmProps = {
  label: string,
  message: string,
  confirmText?: string,
  cancelText?: string,
  confirmIcon?: Icon,
  cancelIcon?: Icon,
  confirmColor?: DefaultMantineColor | 'dimmed',
};

const ConfirmContext = createContext<((props: ConfirmProps) => Promise<boolean>) | null>(null);

const defaultOptions: Required<ConfirmProps> = {
  label: 'Confirm',
  message: 'Are you sure?',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  confirmIcon: IconCheck,
  cancelIcon: IconX,
  confirmColor: 'red',
};

let resolveConfirm: (value: boolean) => void = () => {};

export function ConfirmProvider({ children }: { children: ReactNode }) {
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState<Required<ConfirmProps>>(defaultOptions);

  const onCancel = () => resolveConfirm(false);
  const onConfirm = () => resolveConfirm(true);

  const confirm = useCallback(async (props: ConfirmProps) => {
    resolveConfirm(false); // Cancel potential previous modal

    setData({ ...defaultOptions, ...props });
    setOpen(true);

    const confirmed = await new Promise<boolean>((resolve) => {
      resolveConfirm = resolve;
    });

    setOpen(false);

    return confirmed;
  }, []);

  return (
    <ConfirmContext.Provider value={confirm}>
      <DrawerMenu
        label={data.label}
        message={data.message}
        opened={isOpen}
        onClose={onCancel}
        buttons={[
          {
            text: data.cancelText,
            icon: data.cancelIcon,
            onClick: onCancel,
          },
          {
            text: data.confirmText,
            icon: data.confirmIcon,
            color: data.confirmColor,
            onClick: onConfirm,
          },
        ]}
      />

      {children}
    </ConfirmContext.Provider>
  );
}

export default function useConfirm() {
  const confirm = useContext(ConfirmContext);
  if (!confirm) throw new Error('useConfirm must be used within ConfirmProvider');
  return confirm;
}
