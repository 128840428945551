import {
  Box, LoadingOverlay, Stack, Text, TextInput, UnstyledButton,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import List from '@/components/List';
import UserCard from '@/components/UserCard';
import { useUsers } from '@/db/user/useUser';
import useFilterByAttrs from '@/hooks/useFilterByAttrs';
import classes from './UserList.module.css';

export default function UserList() {
  const { data: users, isPending } = useUsers();

  const [term, setTerm] = useInputState('');
  const filteredUsers = useFilterByAttrs(users, { name: true, username: true }, term);

  if (isPending) return <LoadingOverlay visible />;
  if (!filteredUsers) return <Text c="red">Error!</Text>;

  return (
    <Stack style={{ height: '100%' }} gap={0}>
      <Box p="md" className={classes.search}>
        <TextInput
          leftSection={<IconSearch size={16} strokeWidth={1.5} />}
          value={term}
          onChange={setTerm}
        />
      </Box>
      <List list={filteredUsers} propKeys={['username']} itemHeight={75} minItemWidth={300} flushTop>
        {({ item: user }) => (
          <UnstyledButton
            key={user.username}
            component={Link}
            to={`/users/${user.username}`}
          >
            <UserCard user={user} small />
          </UnstyledButton>
        )}
      </List>
    </Stack>
  );
}
