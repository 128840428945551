import { Icon as TablerIcon } from '@tabler/icons-react';

type DataField = {
  label: string;
  icon: TablerIcon;
  display: false | 'public' | 'private'
};
type VirtualField = Omit<DataField, 'display'> & {
  display: Exclude<DataField['display'], false>;
};

type FilterDefined<T extends Record<string, { display: any } | undefined>> = { [R in {
  [K in keyof T]: T[K] extends undefined ? never : K;
}[keyof T]]: NonNullable<T[R]>; };
type DisplayNot<T extends Record<string, DataField>, V extends DataField['display']> = Pick<T, {
  [K in keyof T]: T[K]['display'] extends V ? never : K;
}[keyof T]>;

function filterFields<
T extends Record<string, DataField>,
R extends Record<string, DataField> | never = never,
>(
  fields: T,
  predicate: (value: DataField) => boolean,
) {
  const entries = Object.entries(fields) as Array<[keyof T, DataField]>;
  return entries.reduce((acc, [key, value]) => {
    if (!predicate(value)) return acc;
    return { ...acc, [key]: value };
  }, {} as R);
}

export default function createDefineFields<T = never>() {
  return function defineFields<
    ObjectFields extends Record<keyof T, DataField | undefined>
    & Record<Exclude<keyof ObjectFields, keyof T>, never>,
    DataFields extends FilterDefined<ObjectFields>,
    TruthyDataFields extends DisplayNot<DataFields, false>,
    VirtualFields extends Record<string, VirtualField>,
    DisplayFields extends TruthyDataFields & VirtualFields,
    PrivateFields extends DisplayNot<DisplayFields, 'public'>,
    DefaultField extends Exclude<keyof DisplayFields, keyof PrivateFields>,
    DefaultFields extends Record<string, DefaultField | Array<DefaultField>>,
  >(
    objectFields: ObjectFields,
    virtualFields: VirtualFields,
    defaultFields: DefaultFields,
  ): {
      dataFields: DataFields;
      displayFields: DisplayFields;
      privateFields: Array<keyof PrivateFields>;
      defaultFields: DefaultFields;
    } {
    const dataFields = filterFields<ObjectFields, DataFields>(
      objectFields,
      (value) => !!value,
    );

    const displayFields = {
      ...filterFields<DataFields, TruthyDataFields>(
        dataFields,
        ({ display }) => display !== false,
      ),
      ...virtualFields,
    } as DisplayFields;

    const privateFields = Object.keys(
      filterFields<DisplayFields, PrivateFields>(
        displayFields,
        ({ display }) => display === 'private',
      ),
    ) as Array<keyof PrivateFields>;

    return {
      dataFields, displayFields, privateFields, defaultFields,
    };
  };
}
