import { z } from 'zod';

export const UserSchema = () => z.object({
  image_url: z.string().nullable(),
  name: z.string(),
  username: z.string(),
  coins_count: z.number(),
  created_at: z.string().datetime(),
});
export type User = z.infer<ReturnType<typeof UserSchema>>;

export const AuthUserSchema = () => UserSchema().extend({
  id: z.number(),
  email: z.string().email(),
  email_verified_at: z.string().datetime().nullable(),
  updated_at: z.string().datetime(),
});
export type AuthUser = z.infer<ReturnType<typeof AuthUserSchema>>;

export const EditAuthUserSchema = () => AuthUserSchema().omit({
  id: true,
  image_url: true,
  coins_count: true,
  created_at: true,
  updated_at: true,
  email_verified_at: true,
}).merge(z.object({
  image: z.instanceof(File).nullable(),
  clear_image: z.boolean(),
}));
export type EditAuthUser = z.infer<ReturnType<typeof EditAuthUserSchema>>;

export const AuthFnUserSchema = () => EditAuthUserSchema().extend({
  password: z.string(),
  password_confirmation: z.string(),
  registration_key: z.string(),
  remember: z.boolean(),
});
export type AuthFnUser = z.infer<ReturnType<typeof AuthFnUserSchema>>;

export const PasswordConfirmSchema = () => AuthFnUserSchema().pick({
  password: true,
});
export type PasswordConfirm = z.infer<ReturnType<typeof PasswordConfirmSchema>>;
