import { LoadingOverlay } from '@mantine/core';
import { Suspense, lazy } from 'react';

const CoinEditor = lazy(() => import('@/components/CoinEditor/CoinEditor'));

export default function NewCoin() {
  return (
    <Suspense fallback={<LoadingOverlay visible />}>
      <CoinEditor />
    </Suspense>
  );
}
