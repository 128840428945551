import {
  Autocomplete, Checkbox, ComboboxItem, Group, OptionsFilter, Radio, UnstyledButton,
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';
import { Filter, createFilter } from '@/components/filters/Filter';
import { CoinDisplayField, coinDisplayFields } from '@/constants/coinFields';
import { CoinBasic } from '@/db/coin/schemas';
import { useCoins } from '@/db/coin/useCoin';
import { CoinFieldType } from '@/helpers/KeysOfType';

type FilterState = {
  searchValue: string;
  doMatchCase: boolean;
  matchType: 'contains' | 'exact' | 'start' | 'end';
};

function getRegex(state: Omit<FilterState, 'searchValue'>, searchValue: string) {
  const withStart = ['exact', 'start'].includes(state.matchType);
  const withEnd = ['exact', 'end'].includes(state.matchType);
  return new RegExp(
    `${withStart ? '^' : ''}${searchValue}${withEnd ? '$' : ''}`,
    state.doMatchCase ? '' : 'i',
  );
}

export default function StringFilter({ field, isExactByDefault }: {
  field: CoinFieldType<string> & CoinDisplayField,
  isExactByDefault?: boolean,
}) {
  const { data: coins } = useCoins();

  const { label, icon: Icon } = coinDisplayFields[field];

  const filterFn = useCallback((filterCoins: Array<CoinBasic>, state: FilterState) => {
    const regex = getRegex(state, state.searchValue);
    return filterCoins.filter((coin) => regex.test(coin[field] ?? ''));
  }, [field]);

  const useFilter = useMemo(() => createFilter<FilterState>(
    field,
    filterFn,
    {
      searchValue: '',
      doMatchCase: false,
      matchType: isExactByDefault ? 'exact' : 'contains',
    },
    ({ searchValue }) => !!searchValue.length,
  ), [field, filterFn, isExactByDefault]);

  const list = useMemo(() => {
    const values = coins?.map((coin) => coin[field] ?? '').filter((v) => !!v);
    return [...new Set(values)];
  }, [field, coins]);

  const [[filter, setFilter], [enabled, setEnabled]] = useFilter();

  const optionsFilter: OptionsFilter = useCallback(({ options, search, limit }) => {
    const withStart = filter.matchType === 'start';
    const withEnd = filter.matchType === 'end';
    if (withStart || withEnd) {
      const regex = new RegExp(`${withStart ? '^' : ''}${search}${withEnd ? '$' : ''}`, 'i');
      return (options as ComboboxItem[])
        .filter((option) => regex.test(option.value))
        .slice(0, limit);
    }
    return (options as ComboboxItem[])
      .filter((option) => option.value.includes(search))
      .slice(0, limit);
  }, [filter.matchType]);

  const clearButton = filter.searchValue && (
    <UnstyledButton
      onClick={() => { setFilter('searchValue', ''); }}
      display="flex"
    >
      <IconX size={16} strokeWidth={1.5} />
    </UnstyledButton>
  );

  return (
    <Filter
      field={field}
      enabled={enabled}
      onChange={() => setEnabled(!enabled)}
    >
      <Radio.Group
        value={filter.matchType}
        onChange={(value) => { setFilter('matchType', value as FilterState['matchType']); }}
      >
        <Group pt="xs" gap="lg">
          {
            [
              <Radio value="contains" label="Contain" key={0} />,
              <Radio value="exact" label="Exact match" key={1} />,
            ][isExactByDefault ? 'reverse' : 'slice']() // slice used as no-op
          }
          <Radio value="start" label="Start with" />
          <Radio value="end" label="End with" />
        </Group>
      </Radio.Group>

      <Autocomplete
        py="md"
        placeholder={label}
        leftSection={<Icon size={16} strokeWidth={1.5} />}
        rightSection={clearButton}
        data={list}
        value={filter.searchValue}
        onChange={(value) => { setFilter('searchValue', value); }}
        filter={optionsFilter}
        limit={6}
      />

      <Checkbox
        label="Match case"
        checked={filter.doMatchCase}
        onChange={() => { setFilter('doMatchCase', !filter.doMatchCase); }}
      />
    </Filter>
  );
}
