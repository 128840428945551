import { Switch, SwitchProps, useMantineColorScheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';

export default function DarkModeSwitch(props: SwitchProps) {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Switch
      size="md"
      label={`${colorScheme === 'light' ? 'En' : 'Dis'}able dark mode`}
      checked={colorScheme === 'dark'}
      onChange={() => toggleColorScheme()}
      color={colorScheme === 'dark' ? 'dark.5' : 'gray.5'}
      onLabel={<IconSun size={16} strokeWidth={1.5} />}
      offLabel={<IconMoonStars size={16} strokeWidth={1.5} />}
      {...props}
    />
  );
}
