import { UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { useLinkClickHandler } from 'react-router-dom';
import CoinImage from '@/components/CoinImage';
import IconLabel from '@/components/IconLabel';
import { CoinDisplayField } from '@/constants/coinFields';
import { CoinBasic } from '@/db/coin/schemas';
import getDisplayFieldData from '@/helpers/getDisplayFieldData';
import cardClasses from './CoinCard.module.css';

export default function CoinCard({ coin, fields, ...other }: {
  coin: CoinBasic, fields: Array<CoinDisplayField>
} & Omit<UnstyledButtonProps, 'className' | 'onClick'>) {
  const topFieldData = getDisplayFieldData(coin, fields[0]);
  const bottomFieldData = getDisplayFieldData(coin, fields[1]);

  const viewCoin = useLinkClickHandler<HTMLButtonElement>(`/coin/${coin.id}`);

  return (
    <UnstyledButton
      className={cardClasses.card}
      onClick={viewCoin}
      {...other}
    >
      <div className={cardClasses.top}>
        <div
          className={cardClasses.title}
          style={{ fontStyle: !coin.denomination ? 'italic' : '' }}
        >
          {coin.denomination || 'Unnamed'}
        </div>
        <CoinImage src={coin.thumb_url} />
      </div>
      <div className={cardClasses.stack}>
        <IconLabel
          doubleLine
          icon={topFieldData.icon}
          text={topFieldData.text}
          italic={topFieldData.unknown}
        />
        {fields[1] && (
          <IconLabel
            doubleLine
            icon={bottomFieldData.icon}
            text={bottomFieldData.text}
            italic={bottomFieldData.unknown}
          />
        )}
      </div>
    </UnstyledButton>
  );
}
