import { UnstyledButton } from '@mantine/core';
import {
  IconSortDescending2 as IconSortAscending2, // These are named wrong in @tabler/icons-react
  IconSortAscending2 as IconSortDescending2,
  Icon as TablerIcon,
} from '@tabler/icons-react';
import {
  Dispatch, ReactNode, createContext, useContext, useMemo, useState,
} from 'react';
import CoinDisplayFieldSelect from '@/components/CoinDisplayFieldSelect';
import { CoinDisplayField, coinDefaultFields } from '@/constants/coinFields';
import { CoinBasic } from '@/db/coin/schemas';
import getDisplayFieldData from '@/helpers/getDisplayFieldData';
import { isNumeric } from '@/helpers/numbers';
import classes from './SortCoins.module.css';

const SortContext = createContext<readonly [
  readonly [CoinDisplayField, Dispatch<React.SetStateAction<CoinDisplayField>>],
  readonly [boolean, Dispatch<React.SetStateAction<boolean>>],
]>([[coinDefaultFields.sort, () => {}], [true, () => {}]]);

export function SortProvider({ children }: { children: ReactNode }) {
  const [sortBy, setSortBy] = useState<CoinDisplayField>(coinDefaultFields.sort);
  const [sortAsc, setSortAsc] = useState(true);

  const value = useMemo(() => [
    [sortBy, setSortBy] as const,
    [sortAsc, setSortAsc] as const,
  ] as const, [sortAsc, sortBy]);

  return (
    <SortContext.Provider value={value}>
      {children}
    </SortContext.Provider>
  );
}

function FadeIcon({ icon: Icon, active }: { icon: TablerIcon, active: boolean }) {
  return (
    <Icon
      size={16}
      strokeWidth={1.5}
      className={classes.icon}
      opacity={active ? 1 : 0}
      transform={active ? 'scale(1)' : 'scale(0)'}
    />
  );
}

export default function SortCoins(props: Omit<Parameters<typeof CoinDisplayFieldSelect>[0], 'value' | 'onChange'>) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [[sortBy, setSortBy], [sortAsc, setSortAsc]] = useContext(SortContext);

  return (
    <CoinDisplayFieldSelect
      value={sortBy}
      onChange={(value: CoinDisplayField) => setSortBy(value)}
      setOpen={setMenuOpen}
      classNames={{ root: classes.sort, section: classes.section }}
      styles={{ root: { flexBasis: menuOpen ? 200 : 0 } }}
      leftSectionPointerEvents="none"
      rightSection={(
        <UnstyledButton
          onClick={() => setSortAsc(!sortAsc)}
          className={classes.button}
        >
          <FadeIcon icon={IconSortAscending2} active={sortAsc} />
          <FadeIcon icon={IconSortDescending2} active={!sortAsc} />
        </UnstyledButton>
      )}
      {...props}
    />
  );
}

export function useSort(coins: Array<CoinBasic>): Array<CoinBasic> {
  const [[sortBy], [sortAsc]] = useContext(SortContext);

  const dirNum = sortAsc ? -1 : 1;

  const withData = coins.map((coin) => {
    const displayFieldData = getDisplayFieldData(coin, sortBy, true);

    return {
      ...coin,
      sortVal: displayFieldData.text,
      sortValUnknown: displayFieldData.unknown,
    };
  });

  const withDataSorted = withData.sort((a, b) => {
    if (a.sortValUnknown) return -dirNum;
    if (b.sortValUnknown) return dirNum;
    if (isNumeric(a.sortVal) && isNumeric(b.sortVal)) return (+a.sortVal - +b.sortVal) * -dirNum;
    if (a.sortVal < b.sortVal) return dirNum;
    if (a.sortVal > b.sortVal) return -dirNum;
    return 0;
  });

  const withoutDataSorted = withDataSorted.map(({ sortVal, sortValUnknown, ...coin }) => coin);

  return withoutDataSorted;
}
