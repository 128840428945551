import { MantineColor } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

export default function useThemeColor() {
  return useLocalStorage<MantineColor>({
    key: 'theme-color',
    defaultValue: 'blue',
    getInitialValueInEffect: false,
  });
}
