import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import {
  Coin, CoinBasic, CoinBasicSchema, CoinSchema,
} from '@/db/coin/schemas';
import $fetch, { parseIfDev } from '@/helpers/$fetch';
import { isNumeric } from '@/helpers/numbers';

async function fetchCoin(id: number): Promise<Coin> {
  const res = await $fetch(`/api/coin/${id}`);
  return parseIfDev(res, CoinSchema);
}

export function useCoin(id: string | undefined) {
  return useQuery({
    queryKey: ['coin', { id: +id! }],
    queryFn: () => fetchCoin(+id!),
    enabled: id !== undefined && isNumeric(id),
  });
}

async function fetchCoins(): Promise<Array<CoinBasic>> {
  const res = await $fetch('/api/coin');
  return parseIfDev(res, () => z.array(CoinBasicSchema()));
}

export function useCoins() {
  return useQuery({ queryKey: ['coins'], queryFn: fetchCoins });
}
