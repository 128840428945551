import { FileButtonProps } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useCallback, useMemo, useRef } from 'react';

export default function useImageform<T extends {
  image: File | null,
  clear_image: boolean,
}>(
  form: UseFormReturnType<T> & {
    setFieldValue: T extends T ? UseFormReturnType<T>['setFieldValue'] : never,
  },
  currentURL: string | null | undefined,
) {
  const resetRefUpload = useRef<() => void>(null);
  const resetRefImgBtn = useRef<() => void>(null);

  // Call this after manually setting the image field
  // value so reselection of the original image works
  const resetRefs = () => {
    resetRefUpload.current?.();
    resetRefImgBtn.current?.();
  };

  const clearEnabled = form.values.image || (currentURL && !form.values.clear_image);
  const resetEnabled = form.values.image || (currentURL && form.values.clear_image);

  const onChangeUpload = (...args: Parameters<FileButtonProps['onChange']>) => {
    form.setFieldValue('clear_image', false);
    form.getInputProps('image').onChange(...args);
  };
  const clearImg = () => {
    resetRefs();
    form.setFieldValue('image', null);
    form.setFieldValue('clear_image', true);
  };
  const resetImg = () => {
    resetRefs();
    form.setFieldValue('image', null);
    form.setFieldValue('clear_image', false);
  };

  // Has to be a string for img elm to properly fall back
  const previewSrc: string = useMemo(
    () => {
      if (form.values.image) return URL.createObjectURL(form.values.image);
      return (!form.values.clear_image && currentURL) || '';
    },
    [form.values.image, form.values.clear_image, currentURL],
  );
  const destroyBlob = useCallback(() => {
    URL.revokeObjectURL(previewSrc);
  }, [previewSrc]);

  const imgProps = useMemo(() => ({
    src: previewSrc,
    onLoad: destroyBlob,
  }), [previewSrc, destroyBlob]);
  const imgWrapperProps = {
    ...form.getInputProps('image'),
    onChange: onChangeUpload,
    resetRef:
    resetRefImgBtn,
  };
  const uploadProps = {
    ...form.getInputProps('image'),
    onChange: onChangeUpload,
    resetRef: resetRefUpload,
  };
  const clearProps = {
    onClick: clearImg,
    disabled: !clearEnabled,
  };
  const resetProps = {
    onClick: resetImg,
    disabled: !resetEnabled,
  };

  return {
    imgProps,
    imgWrapperProps,
    uploadProps,
    clearProps,
    resetProps,
    resetRefs,
  };
}
