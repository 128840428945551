import {
  AppShell,
  MantineThemeOverride, NavLink, Slider, Title,
} from '@mantine/core';
import classes from './defaultStyles.module.css';

const themeComponents: MantineThemeOverride['components'] = {
  Title: Title.extend({
    defaultProps: {
      fw: 'normal',
      my: 'md',
    },
  }),
  NavLink: NavLink.extend({
    classNames: {
      root: classes.navLinkRoot,
      label: classes.navLinkLabel,
    },
  }),
  Slider: Slider.extend({
    classNames: {
      root: classes.sliderRoot,
      label: classes.sliderLabel,
    },
  }),
  AppShell: AppShell.extend({
    classNames: {
      main: classes.appShellMain,
    },
  }),
};

export default themeComponents;
