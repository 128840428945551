import { Loader } from '@mantine/core';
import { Suspense, lazy } from 'react';
import {
  Outlet, Route, createBrowserRouter, createRoutesFromElements,
} from 'react-router-dom';
import Shell from '@/components/Shell/Shell';
import CoinPage from '@/layouts/CoinPage';
import EditCoin from '@/layouts/EditCoin';
import Filters from '@/layouts/Filters';
import Index from '@/layouts/Index';
import NewCoin from '@/layouts/NewCoin';
import NotFound from '@/layouts/NotFound';
import AccountSettings from '@/layouts/Settings/AccountSettings/AccountSettings';
import EditProfile from '@/layouts/Settings/AccountSettings/EditProfile';
import AllSettings from '@/layouts/Settings/AllSettings';
import CollectionSettings from '@/layouts/Settings/CollectionSettings';
import Settings from '@/layouts/Settings/Settings';
import ThemeSettings from '@/layouts/Settings/ThemeSettings';
import Statistics from '@/layouts/Statistics';
import UserList from '@/layouts/UserList';
import UserPage from '@/layouts/UserPage';
import VerifyEmail from '@/layouts/VerifyEmail';

const LoginPage = lazy(() => import('@/layouts/LoginPage'));
const RegisterPage = lazy(() => import('@/layouts/RegisterPage'));

export default createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Suspense fallback={<Loader />}><Outlet /></Suspense>}>
        <Route path="/" element={<Shell />}>
          <Route index element={<Index />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/filters" element={<Filters />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/users/:username" element={<UserPage />} />
          <Route path="/coin/:id" element={<CoinPage />} />
          <Route path="verify" element={<VerifyEmail />} />
        </Route>

        <Route path="/coin/:id/edit" element={<EditCoin />} />
        <Route path="/coin/new" element={<NewCoin />} />

        <Route path="/settings" element={<Settings />}>
          <Route index element={<AllSettings />} />
          <Route path="theme" handle={{ crumb: 'Theme' }} element={<ThemeSettings />} />
          <Route path="collection" handle={{ crumb: 'Collection' }} element={<CollectionSettings />} />
          <Route path="account" handle={{ crumb: 'Account' }}>
            <Route index element={<AccountSettings />} />
            <Route path="profile" handle={{ crumb: 'Profile' }} element={<EditProfile />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </>,
  ),
);
