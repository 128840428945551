import { Image } from '@mantine/core';
import clsx from 'clsx';
import {
  HTMLAttributes, ReactEventHandler, Suspense, lazy,
} from 'react';
import placeholderUrl from '@/assets/icons/placeholder.svg';
import classes from './CoinImage.module.css';

export default function CoinImage({
  src, onLoad, ...other
}: HTMLAttributes<HTMLDivElement> & {
  src: string | null,
  onLoad?: ReactEventHandler<HTMLDivElement>
}) {
  return (
    <div {...other} className={clsx(classes.wrapper, other.className)}>
      <Image
        src={src}
        onLoad={onLoad}
        fit="contain"
        bg="black"
        fallbackSrc={placeholderUrl}
      />
    </div>
  );
}

const Zoomable = lazy(() => import('@/components/Zoomable'));

export function ZoomableCoinImage({ image_url }: { image_url: string | null }) {
  const coinImage = <CoinImage src={image_url} className={classes.image} />;

  return (
    <Suspense fallback={coinImage}>
      <Zoomable>{coinImage}</Zoomable>
    </Suspense>
  );
}
