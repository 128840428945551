import {
  Accordion, ScrollArea, Stack,
} from '@mantine/core';
import { useState } from 'react';
import FilterBar from '@/components/FilterBar';
import DateFilter from '@/components/filters/DateFilter';
import { OpenFilterContext } from '@/components/filters/Filter';
import MaterialFilter from '@/components/filters/MaterialFilter';
import StringFilter from '@/components/filters/StringFilter';

export default function Filters() {
  const [value, setValue] = useState<string | null>(null);

  return (
    <Stack h="100%" gap={0}>
      <FilterBar />
      <ScrollArea>
        <Accordion value={value} onChange={(val) => { setValue(val); }}>
          <OpenFilterContext.Provider value={value}>
            <StringFilter field="denomination" />
            <StringFilter field="origin" />
            <StringFilter field="authority" />
            <StringFilter field="mint" />
            <DateFilter />

            <MaterialFilter />
            <StringFilter field="grade" />
            <StringFilter field="grader" isExactByDefault />
            <StringFilter field="references" isExactByDefault />

            <StringFilter field="seller" />
            <StringFilter field="cert_num" isExactByDefault />
          </OpenFilterContext.Provider>
        </Accordion>
      </ScrollArea>
    </Stack>
  );
}
