import {
  Anchor, Paper, PaperProps, Text, TextareaProps,
} from '@mantine/core';
import { ReactNode } from 'react';

export default function TextBox({ text, ...other }: { text: TextareaProps['value'] | null | undefined } & Omit<PaperProps, 'style'>) {
  const textClean = text?.toString().replace(/[&<>'"]/g, (m) => `&#${m.charCodeAt(0)};`) ?? '';
  const textHTML = textClean
    .replace(/(^|\b|\*|~)_(?!\s)((?:(?!\s?_|\n).)+)_($|\b|\*|~)/g, '$1<i>$2</i>$3') // (first because of \b instead of \B)
    .replace(/(^|\B|_)\*(?!\s)((?:(?!\s?\*|\n).)+)\*($|\B|_)/g, '$1<b>$2</b>$3')
    .replace(/(^|\B|_)~(?!\s)((?:(?!\s?~|\n).)+)~($|\B|_)/g, '$1<s>$2</s>$3')
    .replace(/((?:http|https):\/\/[a-zA-Z0-9-.]+\.[a-zA-Z]{2,4}(?:\/\S*)?[^.])/g, '<a>$1</a>');
  const textDOM = new DOMParser().parseFromString(textHTML ?? '', 'text/html');
  const textNodes = textDOM.documentElement.childNodes[1].childNodes;

  function insertTags(nodes: NodeListOf<ChildNode>) {
    return [...nodes].map((node, idx): ReactNode => {
      const content = node.childNodes.length ? insertTags(node.childNodes) : node.textContent;
      const key = node.nodeType + node.nodeName + (node.textContent ?? '') + idx;

      switch (node.nodeName) {
        case 'I': return <Text key={key} span fs="italic">{content}</Text>;
        case 'B': return <Text key={key} span fw="bold">{content}</Text>;
        case 'S': return <Text key={key} span td="strikethrough">{content}</Text>;
        case 'A': return <Anchor key={key} href={content as string} target="_blank">{content}</Anchor>;
        default: return content;
      }
    });
  }

  return (
    <Paper
      withBorder
      p="sm"
      my="sm"
      style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
      {...other}
    >
      {textNodes.length ? insertTags(textNodes) : <Text c="dimmed" fs="italic">Empty</Text>}
    </Paper>
  );
}
