import { useDebouncedValue } from '@mantine/hooks';
import { useMemo } from 'react';
import { KeysOfType } from '@/helpers/KeysOfType';

export default function useFilterByAttrs<T extends Record<string, unknown>>(
  list: Array<T> | undefined,
  keysObj: { [K in KeysOfType<T, string>]?: boolean },
  term: string,
): Array<T> {
  const [termDebounced] = useDebouncedValue(term, 200);
  const searchTerm = termDebounced.toLowerCase();
  const selectedKeysStable = Object.entries(keysObj).filter(([, v]) => v).map(([k]) => k).join('|');

  return useMemo(() => {
    if (!(list && searchTerm)) return list ?? [];

    const keys = selectedKeysStable.split('|');

    const filteredList = list.filter((item) => keys.some((property) => {
      const value = item[property] as string | null;
      return value?.toLowerCase().includes(searchTerm);
    }));

    return filteredList;
  }, [list, searchTerm, selectedKeysStable]);
}
