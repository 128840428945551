import {
  MutationFunction, UseMutationOptions, useMutation, useQueryClient,
} from '@tanstack/react-query';
import { configureAuth } from 'react-query-auth';
import {
  AuthFnUser, AuthUser, AuthUserSchema, EditAuthUser, PasswordConfirm,
} from '@/db/user/schema';
import $fetch, { parseIfDev } from '@/helpers/$fetch';

const userKey = ['authenticated-user'];

async function authFn(url: string, body: FormData): Promise<AuthUser> {
  const res = await $fetch(url, { method: 'POST', body });
  return parseIfDev(res, AuthUserSchema);
}

export function formatAuthData(user: Partial<AuthFnUser>): FormData {
  const formData = new FormData();
  Object.entries(user).forEach(([key, value]) => {
    if (value == null) {
      formData.append(key, '');
    } else if (value instanceof File) {
      formData.append(key, value);
    } else if (typeof value === 'boolean') {
      formData.append(key, (+value).toString());
    } else {
      formData.append(key, value.toString());
    }
  });
  return formData;
}

async function loginFn(data: Pick<AuthFnUser, 'email' | 'password' | 'remember'>) {
  return authFn('/api/auth/login', formatAuthData(data));
}

async function registerFn(data: Required<AuthFnUser>) {
  return authFn('/api/auth/register', formatAuthData(data));
}

async function userFn(): Promise<AuthUser | null> {
  try {
    const res = await $fetch('/api/auth/user');
    return await parseIfDev(res, AuthUserSchema);
  } catch (error) {
    if (error != null
      && typeof error === 'object'
      && 'message' in error
      && error.message !== 'Unauthenticated.') {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return null;
  }
}

async function editUserFn(data: EditAuthUser): Promise<AuthUser> {
  const body = formatAuthData(data);
  body.append('_method', 'PUT');
  const res = await $fetch('/api/auth/user/profile-information', { method: 'POST', body });
  return parseIfDev(res, AuthUserSchema);
}

function useEditUser(options?: UseMutationOptions<AuthUser, Error, EditAuthUser>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: editUserFn,
    onSuccess: (user, ...rest) => {
      queryClient.setQueryData(userKey, user);
      options?.onSuccess?.(user, ...rest);
    },
  });
}

const logoutFn: MutationFunction<unknown, unknown> = async () => {
  await $fetch('/api/auth/logout', { method: 'POST' });
};

async function logoutOtherDevicesFn(data: PasswordConfirm): Promise<void> {
  await $fetch('/api/auth/logoutother', { method: 'POST', body: formatAuthData(data) });
}

function useLogoutOtherDevices(options?: UseMutationOptions<unknown, Error, PasswordConfirm>) {
  return useMutation({ ...options, mutationFn: logoutOtherDevicesFn });
}

async function deleteUserFn(data: PasswordConfirm): Promise<void> {
  const body = formatAuthData(data);
  body.append('_method', 'DELETE');
  await $fetch('/api/auth/user', { method: 'POST', body });
}

function useDeleteUser(options?: UseMutationOptions<unknown, Error, PasswordConfirm>) {
  return useMutation({ ...options, mutationFn: deleteUserFn });
}

export type Login = Parameters<typeof loginFn>[0];
export type Register = Parameters<typeof registerFn>[0];

export {
  userKey, useEditUser, useLogoutOtherDevices, useDeleteUser,
};

export const {
  useUser, useLogin, useRegister, useLogout, AuthLoader,
} = configureAuth({
  userFn,
  loginFn,
  registerFn,
  logoutFn,
  userKey,
});
