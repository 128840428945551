import { FormErrors, UseFormReturnType } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { UseMutationResult } from '@tanstack/react-query';
import { ZodError, ZodSchema } from 'zod';
import getHttpStatus from './getHttpStatus';

function readCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp(`(^|;\\s*)(${name})=([^;]*)`));
  return (match ? decodeURIComponent(match[3]) : null);
}

export default async function $fetch<FetchParams extends Parameters<typeof fetch>>(
  url: FetchParams[0],
  init?: FetchParams[1],
): Promise<unknown> {
  const token = readCookie('XSRF-TOKEN');
  const res = await fetch(url, { // eslint-disable-line no-restricted-syntax
    ...init,
    headers: {
      ...(token ? {
        'X-XSRF-TOKEN': token,
      } : undefined),
      Accept: 'application/json',
      ...init?.headers,
    },
  });

  if (!res.ok) return Promise.reject(res);

  return res.status !== 204 ? res.json() : null;
}

export async function parseIfDev<T>(res: unknown, schemaGetter: () => ZodSchema<T>): Promise<T> {
  if (import.meta.env.DEV) {
    const parsed = schemaGetter().safeParse(res);
    if (!parsed.success) return Promise.reject(parsed.error);
    return parsed.data;
  }
  return res as T;
}

export function errorMsg(message: string) {
  showNotification({ message, color: 'red' });
}

export async function queryHandler<InputData, ResponseData>(
  mutateAsync: UseMutationResult<ResponseData, unknown, InputData>['mutateAsync'],
  values: InputData,
  form?: UseFormReturnType<InputData>,
): Promise<ResponseData> {
  try {
    return await mutateAsync(values);
  } catch (error) {
    if (import.meta.env.DEV && error instanceof ZodError) {
      errorMsg('Invalid server response.');
    }

    if (error instanceof Response) {
      const httpStatus = getHttpStatus(error.status);
      if (httpStatus) {
        errorMsg(httpStatus);
      } else if (error.headers.get('Content-Type') === 'application/json') {
        const data = await error.json();
        if (data !== null && typeof data === 'object') {
          if ('errors' in data) {
            form?.setErrors(data.errors as FormErrors);
          } else if ('message' in data) {
            errorMsg(data.message as string);
          } else {
            let errorAsString = data.toString();
            if (errorAsString === '[object Object]') errorAsString = 'An error occured';
            errorMsg(error.toString());
          }
        }
        error.json = data;
      }
    }
    throw error;
  }
}
