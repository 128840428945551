import { LoadingOverlay, Text } from '@mantine/core';
import { useUser } from '@/db/auth';

export default function Statistics() {
  const { data: user, isPending } = useUser();

  if (isPending) return <LoadingOverlay visible />;

  return (
    <Text p="lg">
      You have
      {' '}
      {user?.coins_count || 'no'}
      {' '}
      coins. 🎉
    </Text>
  );
}
