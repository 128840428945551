import {
  ColorSwatch, Group, getThemeColor, useMantineColorScheme, useMantineTheme,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import useThemeColor from '@/hooks/useThemeColor';

export default function ThemeColor() {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [themeColor, setThemeColor] = useThemeColor();

  const filteredColors = Object.keys(theme.colors).filter((color) => !['dark', 'gray'].includes(color));

  return (
    <Group gap="xs">
      {filteredColors.map((color) => (
        <ColorSwatch
          key={color}
          color={getThemeColor(color, theme)}
          display="block"
          component="a"
          onClick={() => { setThemeColor(color); }}
        >
          {themeColor === color
          && <IconCheck size={16} color={colorScheme === 'dark' ? 'black' : 'white'} />}
        </ColorSwatch>
      ))}
    </Group>
  );
}
