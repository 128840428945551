import { MouseEventHandler } from 'react';
import { LongPressEventType, useLongPress } from 'use-long-press';

const cancelContextOnTouch: MouseEventHandler<HTMLButtonElement> = (event) => {
  const { nativeEvent } = event;
  const isTouchEvent = window.TouchEvent ? nativeEvent instanceof TouchEvent : 'touches' in nativeEvent;
  if (isTouchEvent || nativeEvent.button === -1) event.preventDefault();
};

export default function useLongTouch<T = unknown>(callback: (context?: T) => void) {
  const openMenuOnLongPress = useLongPress<
  Element,
  T
  >((event, { context }) => {
    callback(context);
  }, { cancelOnMovement: 10, detect: LongPressEventType.Touch });

  return (context?: T) => ({
    ...openMenuOnLongPress(context),
    onContextMenu: cancelContextOnTouch,
  });
}
