import { LoadingOverlay, MantineProvider, createTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { RouterProvider } from 'react-router-dom';
import AuthLoader from '@/components/Shell/AuthLoader';
import { SortProvider } from '@/components/SortCoins';
import { FiltersProvider } from '@/components/filters/FiltersContext';
import { ConfirmProvider } from '@/hooks/useConfirm';
import useFontCycle from '@/hooks/useFontCycle';
import useThemeColor from '@/hooks/useThemeColor';
import router from '@/router';
import themeComponents from '@/themeComponents';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
    mutations: { retry: false },
  },
});

export default function App() {
  const { fontFamily, fontDisplay } = useFontCycle();

  const [themeColor] = useThemeColor();

  const theme = createTheme({
    fontFamily,
    primaryColor: themeColor,
    components: themeComponents,
  });

  return (
    <StrictMode>
      <MantineProvider theme={theme} defaultColorScheme="auto">
        <Notifications style={{ marginBottom: 50 }} />
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools /> */}
          <AuthLoader>
            {fontDisplay}
            <ConfirmProvider>
              <FiltersProvider>
                <SortProvider>
                  <RouterProvider router={router} fallbackElement={<LoadingOverlay visible />} />
                </SortProvider>
              </FiltersProvider>
            </ConfirmProvider>
          </AuthLoader>
        </QueryClientProvider>
      </MantineProvider>
    </StrictMode>
  );
}
