import { useLocalStorage } from '@mantine/hooks';
import { CoinDisplayField, coinDefaultFields, coinPrivateFields } from '@/constants/coinFields';

type SetDisplayField = (val: CoinDisplayField | (
  (prevState: CoinDisplayField) => CoinDisplayField)
) => void;

export default function useCardFields(onlyPublic = false): [
  [CoinDisplayField, CoinDisplayField],
  [SetDisplayField, SetDisplayField],
] {
  const [fieldTop, setFieldTop] = useLocalStorage<CoinDisplayField>({
    key: 'coin-card-field-top',
    defaultValue: coinDefaultFields.display[0],
    getInitialValueInEffect: false,
  });
  const [fieldBottom, setFieldBottom] = useLocalStorage<CoinDisplayField>({
    key: 'coin-card-field-bottom',
    defaultValue: coinDefaultFields.display[1],
    getInitialValueInEffect: false,
  });

  if (!onlyPublic) {
    return [
      [fieldTop!, fieldBottom!],
      [setFieldTop, setFieldBottom],
    ];
  }

  const publicFields = [...new Set<CoinDisplayField>(
    [fieldTop!, fieldBottom!, ...coinDefaultFields.display],
  )].filter((field) => !coinPrivateFields.includes(field as typeof coinPrivateFields[number]));

  return [
    publicFields as [CoinDisplayField, CoinDisplayField],
    [setFieldTop, setFieldBottom],
  ];
}
