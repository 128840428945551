import { useFilterContext } from '@/components/filters/FiltersContext';
import { CoinBasic } from '@/db/coin/schemas';

export default function useFilterCoins() {
  const [filters] = useFilterContext();

  const filterFns = Object.entries(filters)
    .filter(([, { enabled }]) => enabled)
    .map(([key, { filterFn }]) => [key, filterFn] as const);

  return (coins: Array<CoinBasic>) => {
    let filteredCoins = coins;
    filterFns.forEach(([key, filterFn]) => {
      filteredCoins = filterFn(filteredCoins, filters[key].values);
    });
    return filteredCoins;
  };
}
