import {
  Group, LoadingOverlay, Stack, Text, TextInput,
} from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconEye, IconPencil, IconSearch, IconTrash,
} from '@tabler/icons-react';
import { useState } from 'react';
import { useLinkClickHandler, useSearchParams } from 'react-router-dom';
import CoinCard from '@/components/CoinCard';
import CoinPreview from '@/components/CoinPreview';
import DrawerMenu from '@/components/DrawerMenu';
import FilterBar from '@/components/FilterBar';
import List from '@/components/List';
import SortCoins, { useSort } from '@/components/SortCoins';
import { CoinBasic } from '@/db/coin/schemas';
import { useCoins } from '@/db/coin/useCoin';
import { useDeleteCoin } from '@/db/coin/useDeleteCoin';
import { queryHandler } from '@/helpers/$fetch';
import useCoinFilter from '@/hooks/useCoinFilter';
import useConfirm from '@/hooks/useConfirm';
import useFilterCoins from '@/hooks/useFilterCoins';
import useLongTouch from '@/hooks/useLongTouch';

export default function MyCollection() {
  const { data: coins, isPending } = useCoins();
  const [searchParams, setSearchParams] = useSearchParams();
  const term = searchParams.get('q') ?? '';
  const [debouncedTerm] = useDebouncedValue(term, 200);
  const filterCoins = useFilterCoins();
  const sortedFilteredCoins = useCoinFilter(filterCoins(useSort(coins ?? [])), debouncedTerm);

  const [isMenuOpen, { open: openMenu, close: closeMenu }] = useDisclosure(false);
  const [menuCoin, setMenuCoin] = useState<CoinBasic>();
  const coinMenu = useLongTouch<CoinBasic>((coin) => {
    setMenuCoin(coin);
    openMenu();
  });

  const confirm = useConfirm();
  const { mutateAsync: deleteCoin, isPending: isDeleting } = useDeleteCoin();

  const confirmDeleteCoin = async () => {
    if (menuCoin?.id) {
      const confirmed = await confirm({
        label: 'Delete Coin?',
        message: 'Removed items are not recoverable. Do you still wish to delete this coin?',
        cancelText: 'Keep',
        confirmText: `Delete ${menuCoin?.denomination ? ` "${menuCoin.denomination}"` : ''}`,
        confirmIcon: IconTrash,
      });
      if (confirmed) {
        try {
          await queryHandler(deleteCoin, { id: menuCoin.id });
          showNotification({ message: `Coin ${menuCoin?.denomination ? `"${menuCoin.denomination}" ` : ''}deleted` });
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const viewCoin = useLinkClickHandler<HTMLAnchorElement>(`/coin/${menuCoin?.id}`);
  const editCoin = useLinkClickHandler<HTMLAnchorElement>(`/coin/${menuCoin?.id}/edit`);

  if (isPending || isDeleting) return <LoadingOverlay visible />;
  if (!sortedFilteredCoins) return <Text c="red">Something went wrong</Text>;

  return (
    <Stack style={{ height: '100%' }} gap={0}>
      <FilterBar />
      <Group
        p="md"
        style={{
          zIndex: 1,
          backgroundColor: 'light-dark(white, var(--mantine-color-dark-7))',
        }}
      >
        <TextInput
          placeholder="Search"
          leftSection={<IconSearch size={16} strokeWidth={1.5} />}
          value={searchParams.get('q') ?? ''}
          onChange={(e) => { setSearchParams({ q: e.target.value || [] }); }}
          style={{ flex: '1 1 0' }}
        />
        <SortCoins />
      </Group>
      <List
        list={sortedFilteredCoins}
        propKeys={['id', 'updated_at']}
        itemHeight="135%"
        minItemWidth={150}
        flushTop
      >
        {({ item: { fields, ...coin } }) => (
          <CoinCard
            fields={fields}
            coin={coin}
            {...coinMenu(coin)}
          />
        )}
      </List>
      <DrawerMenu
        opened={isMenuOpen}
        onClose={closeMenu}
        header={<CoinPreview coin={menuCoin} />}
        buttons={[
          { text: 'View', icon: IconEye, onClick: viewCoin },
          { text: 'Edit', icon: IconPencil, onClick: editCoin },
          {
            text: 'Delete', icon: IconTrash, onClick: confirmDeleteCoin, color: 'red',
          },
        ]}
      />
    </Stack>
  );
}
